import { axiosInstance } from '@api/axios.provider';
import { ObjectIdResponse } from '@api/common/response/object-id.response';
import { CreateEventDto } from './dto/create-event.dto';
import { GetEventListParams } from './dto/get-event-list.params';
import { UpdateEventDto } from './dto/update-event.dto';
import { EventListResponse } from './response/event-list.response';
import { EventResponse } from './response/event.response';

export async function apiCreateEvent(payload: CreateEventDto): Promise<ObjectIdResponse> {
  const response = await axiosInstance.post<ObjectIdResponse>('/api/v3/events', payload);

  return response.data;
}

export async function apiGetEventList(params: GetEventListParams): Promise<EventListResponse> {
  const response = await axiosInstance.get<EventListResponse>('/api/v3/events', { params });

  return response.data;
}

export async function apiGetEvent(id: string): Promise<EventResponse> {
  const response = await axiosInstance.get<EventResponse>(`/api/v3/events/${id}`);

  return response.data;
}

export async function apiUpdateEvent(
  id: string,
  payload: UpdateEventDto,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.patch<ObjectIdResponse>(`/api/v3/events/${id}`, payload);

  return response.data;
}

export async function apiDeleteEvent(id: string): Promise<void> {
  const response = await axiosInstance.delete<void>(`/api/v3/events/${id}`);

  return response.data;
}
