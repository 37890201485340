import { Operator } from '@api/common/dto/search.params.dto';
import { VendorListResponse } from '@api/vendors/response/vendor-list.response';
import { apiGetVendorList } from '@api/vendors/vendors.api';
import { createErrorToast } from '@components/NotificationsHandler';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { actionPurgeAuthState } from '@store/auth/auth.slice';
import { RootState } from '@store/index';
import { toggleDirection } from '@utils/common';
import { AllColumnsStatePayload, ColumnStatePayload } from '@utils/types/column';
import { VendorFilterType, VendorSortType, VendorStoreState, VendorWithSelect } from './types';

export const selectedVendorsAdapter = createEntityAdapter<VendorWithSelect, string>({
  selectId: entity => entity._id,
});

/**
 * Vendor List
 */
export const actionGetVendorList = createAsyncThunk<VendorListResponse, void, { state: RootState }>(
  'vendors/get-list',
  async (_, thunkAPI) => {
    const { page, limit, search, column, operator, sort, direction } =
      thunkAPI.getState().vendors.query;
    const offset = (page - 1) * limit;

    try {
      return await apiGetVendorList({ offset, limit, search, column, operator, sort, direction });
    } catch (error) {
      createErrorToast(error);
      return thunkAPI.rejectWithValue(error);
    }
  },
);

/**
 * Query
 */
export const actionVendorSetPage = createAsyncThunk<void, number, { state: RootState }>(
  'vendors/set-page',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetVendorList());
  },
);

export const actionVendorSetLimit = createAsyncThunk<void, number, { state: RootState }>(
  'vendors/set-limit',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetVendorList());
  },
);

export const actionVendorSetSearch = createAsyncThunk<
  void,
  string | undefined,
  { state: RootState }
>('vendors/set-search', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetVendorList());
});

export const actionVendorSetColumn = createAsyncThunk<void, string, { state: RootState }>(
  'vendors/set-column',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().vendors.query.search) {
      thunkAPI.dispatch(actionGetVendorList());
    }
  },
);

export const actionVendorSetOperator = createAsyncThunk<void, string, { state: RootState }>(
  'vendors/set-operator',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().vendors.query.search) {
      thunkAPI.dispatch(actionGetVendorList());
    }
  },
);

export const actionVendorSetSort = createAsyncThunk<void, string | undefined, { state: RootState }>(
  'vendors/set-sort',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetVendorList());
  },
);

const initialState: VendorStoreState = {
  data: {
    count: 0,
    list: [],
  },
  selected: selectedVendorsAdapter.getInitialState(),
  query: {
    page: 1,
    limit: 20,
    search: undefined,
    column: 'login',
    operator: 'co',
    sort: undefined,
    direction: undefined,
  },
  hidden_columns: [],
  is_loading: false,
};

export const vendorSlice = createSlice({
  name: 'vendorSlice',
  initialState,
  reducers: {
    /**
     * Columns
     */
    actionVendorSetColumnState: (state, action: PayloadAction<ColumnStatePayload>) => {
      const set = new Set(state.hidden_columns);
      if (action.payload.is_visible) {
        set.delete(action.payload.label);
      } else {
        set.add(action.payload.label);
      }

      state.hidden_columns = Array.from(set);
    },
    actionVendorSetAllColumnsState: (state, action: PayloadAction<AllColumnsStatePayload>) => {
      if (action.payload.is_visible) {
        state.hidden_columns = [];
      } else {
        state.hidden_columns = action.payload.labels;
      }
    },

    /**
     * Select
     */
    actionVendorSelectAccount: (state, action: PayloadAction<string>) => {
      const entity = state.data.list.find(item => item._id === action.payload);
      if (entity) {
        entity.is_selected = true;
        selectedVendorsAdapter.addOne(state.selected, entity);
      }
    },
    actionVendorDeselectAccount: (state, action: PayloadAction<string>) => {
      const entity = state.data.list.find(item => item._id === action.payload);
      if (entity) {
        entity.is_selected = false;
      }
      selectedVendorsAdapter.removeOne(state.selected, action.payload);
    },
    actionVendorSetAccountsSelectState: (state, action: PayloadAction<boolean>) => {
      const entities = state.data.list.filter(item => item.is_selected !== action.payload);
      const keys = entities.map(item => item._id);

      if (action.payload) {
        selectedVendorsAdapter.addMany(state.selected, entities);
      } else {
        selectedVendorsAdapter.removeMany(state.selected, keys);
      }

      state.data.list = state.data.list.map(item => ({ ...item, is_selected: action.payload }));
    },
    actionVendorClearSelectState: state => {
      selectedVendorsAdapter.removeAll(state.selected);
      state.data.list = state.data.list.map(item => ({ ...item, is_selected: false }));
    },
  },

  extraReducers: builder => {
    /**
     * Vendor List
     */
    builder.addCase(actionGetVendorList.pending, state => {
      state.is_loading = true;
    });
    builder.addCase(actionGetVendorList.fulfilled, (state, action) => {
      state.data.count = action.payload.count;
      state.data.list = action.payload.data.map(item => ({
        ...item,
        is_selected: state.selected.ids.includes(item._id),
      }));
      state.is_loading = false;
    });
    builder.addCase(actionGetVendorList.rejected, state => {
      state.is_loading = false;
    });

    /**
     * Query
     */
    builder.addCase(actionVendorSetPage.pending, (state, action) => {
      state.query.page = action.meta.arg;
    });
    builder.addCase(actionVendorSetLimit.pending, (state, action) => {
      state.query.limit = action.meta.arg;
      state.query.page = 1;
    });
    builder.addCase(actionVendorSetSearch.pending, (state, action) => {
      state.query.search = action.meta.arg || undefined; // to avoid empty string
      state.query.page = 1;
    });
    builder.addCase(actionVendorSetColumn.pending, (state, action) => {
      state.query.column = action.meta.arg as VendorFilterType;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionVendorSetOperator.pending, (state, action) => {
      state.query.operator = action.meta.arg as Operator;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionVendorSetSort.pending, (state, action) => {
      state.query.direction =
        state.query.sort === action.meta.arg ? toggleDirection(state.query.direction) : 'asc';
      state.query.sort = state.query.direction ? (action.meta.arg as VendorSortType) : undefined;
    });

    /**
     * Clear
     */
    builder.addMatcher(
      action => action.type === actionPurgeAuthState.type,
      () => {
        return initialState;
      },
    );
  },
});

export const {
  actionVendorSetColumnState,
  actionVendorSetAllColumnsState,
  actionVendorSelectAccount,
  actionVendorDeselectAccount,
  actionVendorSetAccountsSelectState,
  actionVendorClearSelectState,
} = vendorSlice.actions;
