import { axiosInstance } from '@api/axios.provider';
import { CreateDeviceModelBody } from './dto/create-device-model.body';
import { DeleteDeviceModelsBody } from './dto/delete-device-models.body';
import { GetDeviceModelListParams } from './dto/get-device-model-list.params';
import { DeviceModelListResponse } from './response/device-model-list.response';

export async function apiCreateDeviceModel(body: CreateDeviceModelBody): Promise<void> {
  const response = await axiosInstance.post<void>('/api/v3/devices/models', body);

  return response.data;
}

export async function apiGetDeviceModelList(
  params: GetDeviceModelListParams,
): Promise<DeviceModelListResponse> {
  const response = await axiosInstance.get<DeviceModelListResponse>('/api/v3/devices/models', {
    params,
  });

  return response.data;
}

export async function apiDeleteDeviceModels(body: DeleteDeviceModelsBody): Promise<void> {
  const response = await axiosInstance.delete<void>('/api/v3/devices/models', {
    data: body,
  });

  return response.data;
}
