import { apiGetAdminList } from '@api/admins/admins.api';
import { AdminListResponse } from '@api/admins/response/admin-list.response';
import { Operator } from '@api/common/dto/search.params.dto';
import { createErrorToast } from '@components/NotificationsHandler';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { actionPurgeAuthState } from '@store/auth/auth.slice';
import { RootState } from '@store/index';
import { toggleDirection } from '@utils/common';
import { AllColumnsStatePayload, ColumnStatePayload } from '@utils/types/column';
import { AdminFilterType, AdminSortType, AdminStoreState, AdminWithSelect } from './types';

export const selectedAdminsAdapter = createEntityAdapter<AdminWithSelect, string>({
  selectId: entity => entity._id,
});

const initialState: AdminStoreState = {
  data: {
    count: 0,
    list: [],
  },
  selected: selectedAdminsAdapter.getInitialState(),
  query: {
    page: 1,
    limit: 20,
    search: undefined,
    column: 'login',
    operator: 'co',
    sort: undefined,
    direction: undefined,
  },
  hidden_columns: [],
  is_loading: false,
};

/**
 * Admin List
 */
export const actionGetAdminList = createAsyncThunk<AdminListResponse, void, { state: RootState }>(
  'admins/get-list',
  async (_, thunkAPI) => {
    const { page, limit, search, column, operator, sort, direction } =
      thunkAPI.getState().admins.query;
    const offset = (page - 1) * limit;

    try {
      return await apiGetAdminList({ offset, limit, search, column, operator, sort, direction });
    } catch (error) {
      createErrorToast(error);
      return thunkAPI.rejectWithValue(error);
    }
  },
);

/**
 * Query
 */
export const actionAdminSetPage = createAsyncThunk<void, number, { state: RootState }>(
  'admins/set-page',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetAdminList());
  },
);

export const actionAdminSetLimit = createAsyncThunk<void, number, { state: RootState }>(
  'admins/set-limit',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetAdminList());
  },
);

export const actionAdminSetSearch = createAsyncThunk<
  void,
  string | undefined,
  { state: RootState }
>('admins/set-search', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetAdminList());
});

export const actionAdminSetColumn = createAsyncThunk<void, string, { state: RootState }>(
  'admins/set-column',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().admins.query.search) {
      thunkAPI.dispatch(actionGetAdminList());
    }
  },
);

export const actionAdminSetOperator = createAsyncThunk<void, string, { state: RootState }>(
  'admins/set-operator',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().admins.query.search) {
      thunkAPI.dispatch(actionGetAdminList());
    }
  },
);

export const actionAdminSetSort = createAsyncThunk<void, string | undefined, { state: RootState }>(
  'admins/set-sort',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetAdminList());
  },
);

export const adminSlice = createSlice({
  name: 'adminSlice',
  initialState,
  reducers: {
    /**
     * Columns
     */
    actionAdminSetColumnState: (state, action: PayloadAction<ColumnStatePayload>) => {
      const set = new Set(state.hidden_columns);
      if (action.payload.is_visible) {
        set.delete(action.payload.label);
      } else {
        set.add(action.payload.label);
      }

      state.hidden_columns = Array.from(set);
    },
    actionAdminSetAllColumnsState: (state, action: PayloadAction<AllColumnsStatePayload>) => {
      if (action.payload.is_visible) {
        state.hidden_columns = [];
      } else {
        state.hidden_columns = action.payload.labels;
      }
    },

    /**
     * Select
     */
    actionAdminSelectAccount: (state, action: PayloadAction<string>) => {
      const entity = state.data.list.find(item => item._id === action.payload);
      if (entity) {
        entity.is_selected = true;
        selectedAdminsAdapter.addOne(state.selected, entity);
      }
    },
    actionAdminDeselectAccount: (state, action: PayloadAction<string>) => {
      const entity = state.data.list.find(item => item._id === action.payload);
      if (entity) {
        entity.is_selected = false;
      }
      selectedAdminsAdapter.removeOne(state.selected, action.payload);
    },
    actionAdminSetAccountsSelectState: (state, action: PayloadAction<boolean>) => {
      const entities = state.data.list.filter(item => item.is_selected !== action.payload);
      const keys = entities.map(item => item._id);

      if (action.payload) {
        selectedAdminsAdapter.addMany(state.selected, entities);
      } else {
        selectedAdminsAdapter.removeMany(state.selected, keys);
      }

      state.data.list = state.data.list.map(item => ({ ...item, is_selected: action.payload }));
    },
    actionAdminClearSelectState: state => {
      selectedAdminsAdapter.removeAll(state.selected);
      state.data.list = state.data.list.map(item => ({ ...item, is_selected: false }));
    },
  },

  extraReducers: builder => {
    /**
     * Admin List
     */
    builder.addCase(actionGetAdminList.pending, state => {
      state.is_loading = true;
    });
    builder.addCase(actionGetAdminList.fulfilled, (state, action) => {
      state.data.count = action.payload.count;
      state.data.list = action.payload.data.map(item => ({
        ...item,
        is_selected: state.selected.ids.includes(item._id),
      }));
      state.is_loading = false;
    });
    builder.addCase(actionGetAdminList.rejected, state => {
      state.is_loading = false;
    });

    /**
     * Query
     */
    builder.addCase(actionAdminSetPage.pending, (state, action) => {
      state.query.page = action.meta.arg;
    });
    builder.addCase(actionAdminSetLimit.pending, (state, action) => {
      state.query.limit = action.meta.arg;
      state.query.page = 1;
    });
    builder.addCase(actionAdminSetSearch.pending, (state, action) => {
      state.query.search = action.meta.arg || undefined; // to avoid empty string
      state.query.page = 1;
    });
    builder.addCase(actionAdminSetColumn.pending, (state, action) => {
      state.query.column = action.meta.arg as AdminFilterType;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionAdminSetOperator.pending, (state, action) => {
      state.query.operator = action.meta.arg as Operator;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionAdminSetSort.pending, (state, action) => {
      state.query.direction =
        state.query.sort === action.meta.arg ? toggleDirection(state.query.direction) : 'asc';
      state.query.sort = state.query.direction ? (action.meta.arg as AdminSortType) : undefined;
    });

    /**
     * Clear
     */
    builder.addMatcher(
      action => action.type === actionPurgeAuthState.type,
      () => {
        return initialState;
      },
    );
  },
});

export const {
  actionAdminSetColumnState,
  actionAdminSetAllColumnsState,
  actionAdminSelectAccount,
  actionAdminDeselectAccount,
  actionAdminSetAccountsSelectState,
  actionAdminClearSelectState,
} = adminSlice.actions;
