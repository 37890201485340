import { ChangePasswordDto } from '@api/common/dto/change-password.dto';
import { ChangeStatusDto } from '@api/common/dto/change-status.dto';
import { UpdatePasswordDto } from '@api/common/dto/update-password.dto';
import { ObjectIdResponse } from '@api/common/response/object-id.response';
import { axiosInstance } from '../axios.provider';
import { CreateVendorDto } from './dto/create-vendor.dto';
import { GetVendorListParams } from './dto/get-vendor-list.params';
import { UpdateVendorProfileDto } from './dto/update-vendor-profile.dto';
import { UpdateVendorDto } from './dto/update-vendor.dto';
import { UpdateVendorProfileResponse } from './response/update-vendor-profile.response';
import { VendorListResponse } from './response/vendor-list.response';
import { VendorProfileResponse } from './response/vendor-profile.response';
import { VendorResponse } from './response/vendor.response';

export async function apiCreateVendor(payload: CreateVendorDto): Promise<ObjectIdResponse> {
  const response = await axiosInstance.post<ObjectIdResponse>('/api/v3/vendors', payload);

  return response.data;
}

export async function apiGetVendorList(params: GetVendorListParams): Promise<VendorListResponse> {
  const response = await axiosInstance.get<VendorListResponse>('/api/v3/vendors', { params });

  return response.data;
}

export async function apiDeleteManyVendors(ids: string[]): Promise<void> {
  const response = await axiosInstance.delete<void>('/api/v3/vendors', { data: { ids } });

  return response.data;
}

export async function apiGetVendor(id: string): Promise<VendorResponse> {
  const response = await axiosInstance.get<VendorResponse>(`/api/v3/vendors/${id}`);

  return response.data;
}

export async function apiUpdateVendor(
  id: string,
  payload: UpdateVendorDto,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.patch<ObjectIdResponse>(`/api/v3/vendors/${id}`, payload);

  return response.data;
}

export async function apiDeleteVendor(id: string): Promise<void> {
  const response = await axiosInstance.delete<void>(`/api/v3/vendors/${id}`);

  return response.data;
}

export async function apiChangeVendorPassword(
  id: string,
  payload: ChangePasswordDto,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.patch<ObjectIdResponse>(
    `/api/v3/vendors/${id}/change-password`,
    payload,
  );

  return response.data;
}

export async function apiChangeVendorStatus(
  id: string,
  payload: ChangeStatusDto,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.patch<ObjectIdResponse>(
    `/api/v3/vendors/${id}/change-status`,
    payload,
  );

  return response.data;
}

export async function apiGetVendorProfile(): Promise<VendorProfileResponse> {
  const response = await axiosInstance.get<VendorProfileResponse>('/api/v3/vendors/me/profile');

  return response.data;
}

export async function apiUpdateVendorProfile(
  payload: UpdateVendorProfileDto,
): Promise<UpdateVendorProfileResponse> {
  const response = await axiosInstance.patch<UpdateVendorProfileResponse>(
    '/api/v3/vendors/me/profile',
    payload,
  );

  return response.data;
}

export async function apiUpdateVendorPassword(
  payload: UpdatePasswordDto,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.patch<ObjectIdResponse>(
    '/api/v3/vendors/me/profile/password',
    payload,
  );

  return response.data;
}
