import { apiGetNotificationList } from '@api/notifications/notifications.api';
import { NotificationListResponse } from '@api/notifications/response/notification-list.response';
import { createErrorToast } from '@components/NotificationsHandler';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionPurgeAuthState } from '@store/auth/auth.slice';
import { RootState } from '@store/index';
import { NotificationStoreState, NotificationType } from './types';

export const NOTIFICATIONS_PER_PAGE = 12;

const initialState: NotificationStoreState = {
  types: [
    { type: NotificationType.CARTRIDGE_REPLACED, title: 'cartridge replaced', value: true },
    { type: NotificationType.CARTRIDGE_EXPIRED_SOON, title: 'cartridge expired soon', value: true },
    { type: NotificationType.CARTRIDGE_EXPIRED, title: 'cartridge expired', value: true },
    {
      type: NotificationType.FILTRATION_SYSTEM_CHANGED,
      title: 'filtration system changed',
      value: true,
    },
    { type: NotificationType.FIRMWARE_UPDATED, title: 'firmware update', value: true },
    { type: NotificationType.FIRMWARE_AVAILABLE, title: 'firmware available', value: true },
    { type: NotificationType.BATTERY_LOW, title: 'battery low', value: true },
    { type: NotificationType.BATTERY_DISCHARGED, title: 'battery discharged', value: true },
    { type: NotificationType.DEVICE_STATUS_OFFLINE, title: 'device status offline', value: true },
    { type: NotificationType.DEVICE_STATUS_LEAK, title: 'device status leak', value: true },
    { type: NotificationType.DEVICE_FAILURE_PUMP, title: 'device failure pump', value: true },
    {
      type: NotificationType.DEVICE_FAILURE_PRESSURE_SENSOR,
      title: 'device failure pressure sensor',
      value: true,
    },
    {
      type: NotificationType.DEVICE_FAILURE_FLOW_SENSOR_INPUT,
      title: 'device failure flow sensor input',
      value: true,
    },
    {
      type: NotificationType.DEVICE_FAILURE_FLOW_SENSOR_OUTPUT,
      title: 'device failure flow sensor output',
      value: true,
    },
    {
      type: NotificationType.DEVICE_FAILURE_TEMPERATURE_SENSOR_INPUT,
      title: 'device failure temperature sensor input',
      value: true,
    },
    {
      type: NotificationType.DEVICE_FAILURE_TEMPERATURE_SENSOR_OUTPUT,
      title: 'device failure temperature sensor output',
      value: true,
    },
    {
      type: NotificationType.DEVICE_FAILURE_TDS_SENSOR_INPUT,
      title: 'device failure tds sensor input',
      value: true,
    },
    {
      type: NotificationType.DEVICE_FAILURE_TDS_SENSOR_OUTPUT,
      title: 'device failure tds sensor output',
      value: true,
    },
    {
      type: NotificationType.DEVICE_FAILURE_NO_WATER,
      title: 'device failure no water',
      value: true,
    },
    {
      type: NotificationType.WATER_TEMPERATURE_TOO_HIGH,
      title: 'water temperature too high',
      value: true,
    },
    {
      type: NotificationType.WATER_TEMPERATURE_TOO_LOW,
      title: 'water temperature too low',
      value: true,
    },
    {
      type: NotificationType.WATER_PRESSURE_TOO_HIGH,
      title: 'water pressure too high',
      value: true,
    },
    { type: NotificationType.WATER_PRESSURE_TOO_LOW, title: 'water pressure too low', value: true },
    { type: NotificationType.WATER_TDS_TOO_HIGH, title: 'water tds too high', value: true },
  ],
  isLoading: false,
  offset: 0,
  limit: NOTIFICATIONS_PER_PAGE,
  data: {
    count: 0,
    data: [],
  },
};

export const actionNotificationFetch = createAsyncThunk<
  NotificationListResponse,
  { devices: string[] },
  { state: RootState }
>('notification/fetch', async ({ devices }, thunkAPI) => {
  const { offset, limit, types } = thunkAPI.getState().notifications;

  try {
    return await apiGetNotificationList({
      offset,
      limit,
      types: types.filter(item => item.value).map(item => item.type),
      devices,
    });
  } catch (error) {
    createErrorToast(error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const actionNotificationRefresh = createAsyncThunk<
  NotificationListResponse,
  { devices: string[] },
  { state: RootState }
>('notification/refresh', async ({ devices }, thunkAPI) => {
  const { limit, types } = thunkAPI.getState().notifications;

  try {
    return await apiGetNotificationList({
      offset: 0,
      limit,
      types: types.filter(item => item.value).map(item => item.type),
      devices,
    });
  } catch (error) {
    createErrorToast(error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    actionNotificationSetLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    actionNotificationHideAllFilter: state => {
      state.types = state.types.map(item => {
        item.value = false;
        return item;
      });
    },
    actionNotificationShowAllFilter: state => {
      state.types = state.types.map(item => {
        item.value = true;
        return item;
      });
    },
    actionNotificationSetFilter: (
      state,
      action: PayloadAction<{ type: string; value: boolean }>,
    ) => {
      const filter = state.types.find(item => item.type === action.payload.type);
      if (filter) {
        filter.value = action.payload.value;
      }
    },
    actionNotificationResetData: state => {
      state.isLoading = false;
      state.offset = 0;
      state.data.count = 0;
      state.data.data = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(actionNotificationFetch.pending, state => {
      state.isLoading = true;
      state.offset += state.limit;
    });
    builder.addCase(actionNotificationFetch.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.count = action.payload.count;
      state.data.data.push(...action.payload.data);
    });
    builder.addCase(actionNotificationFetch.rejected, state => {
      state.isLoading = false;
      state.offset -= state.limit;
    });

    builder.addCase(actionNotificationRefresh.pending, state => {
      state.isLoading = true;
      state.offset = 0;
      state.data.count = 0;
      state.data.data = [];
    });
    builder.addCase(actionNotificationRefresh.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.count = action.payload.count;
      state.data.data = action.payload.data;
    });
    builder.addCase(actionNotificationRefresh.rejected, state => {
      state.isLoading = false;
    });

    /**
     * Clear
     */
    builder.addMatcher(
      action => action.type === actionPurgeAuthState.type,
      () => {
        return initialState;
      },
    );
  },
});

export const {
  actionNotificationSetLimit,
  actionNotificationResetData,
  actionNotificationHideAllFilter,
  actionNotificationShowAllFilter,
  actionNotificationSetFilter,
} = notificationSlice.actions;
