import { Operator } from '@api/common/dto/search.params.dto';
import { apiGetDeviceList } from '@api/devices/devices.api';
import { DeviceListFilterType, DeviceListSortType } from '@api/devices/dto/get-device-list.params';
import { DeviceListResponse } from '@api/devices/response/device-list.response';
import { createErrorToast } from '@components/NotificationsHandler';
import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { actionPurgeAuthState } from '@store/auth/auth.slice';
import { RootState } from '@store/index';
import { getRandomColor, toggleDirection } from '@utils/common';
import { AllColumnsStatePayload, ColumnStatePayload } from '@utils/types/column';
import { DeviceStoreState, SelectedDevices } from './types';

export const selectedDevicesAdapter = createEntityAdapter<SelectedDevices, string>({
  selectId: entity => entity.bssid,
});

/**
 * List
 */
export const actionGetDeviceList = createAsyncThunk<DeviceListResponse, void, { state: RootState }>(
  'devices/get-list',
  async (_, thunkAPI) => {
    const { page, limit, search, column, operator, sort, direction } =
      thunkAPI.getState().devices.query;
    const offset = (page - 1) * limit;

    try {
      return await apiGetDeviceList({ offset, limit, search, column, operator, sort, direction });
    } catch (error) {
      createErrorToast(error);
      return thunkAPI.rejectWithValue(error);
    }
  },
);

/**
 * Query
 */
export const actionDeviceSetPage = createAsyncThunk<void, number, { state: RootState }>(
  'devices/set-page',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetDeviceList());
  },
);

export const actionDeviceSetLimit = createAsyncThunk<void, number, { state: RootState }>(
  'devices/set-limit',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetDeviceList());
  },
);

export const actionDeviceSetSearch = createAsyncThunk<
  void,
  string | undefined,
  { state: RootState }
>('devices/set-search', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetDeviceList());
});

export const actionDeviceSetColumn = createAsyncThunk<void, string, { state: RootState }>(
  'devices/set-column',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().devices.query.search) {
      thunkAPI.dispatch(actionGetDeviceList());
    }
  },
);

export const actionDeviceSetOperator = createAsyncThunk<void, string, { state: RootState }>(
  'devices/set-operator',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().devices.query.search) {
      thunkAPI.dispatch(actionGetDeviceList());
    }
  },
);

export const actionDeviceSetSort = createAsyncThunk<void, string | undefined, { state: RootState }>(
  'devices/set-sort',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetDeviceList());
  },
);

const initialState: DeviceStoreState = {
  data: {
    count: 0,
    list: [],
  },
  selected: selectedDevicesAdapter.getInitialState(),
  query: {
    page: 1,
    limit: 20,
    search: undefined,
    column: 'bssid',
    operator: 'co',
    sort: undefined,
    direction: undefined,
  },
  hidden_columns: [],
  is_loading: false,
};

export const deviceSlice = createSlice({
  name: 'deviceSlice',
  initialState,
  reducers: {
    /**
     * Columns
     */
    actionDeviceSetColumnState: (state, action: PayloadAction<ColumnStatePayload>) => {
      const set = new Set(state.hidden_columns);
      if (action.payload.is_visible) {
        set.delete(action.payload.label);
      } else {
        set.add(action.payload.label);
      }

      state.hidden_columns = Array.from(set);
    },
    actionDeviceSetAllColumnsState: (state, action: PayloadAction<AllColumnsStatePayload>) => {
      if (action.payload.is_visible) {
        state.hidden_columns = [];
      } else {
        state.hidden_columns = action.payload.labels;
      }
    },

    /**
     * Select
     */
    actionDeviceSelect: (state, action: PayloadAction<{ bssid: string }>) => {
      const entity = state.data.list.find(item => item.bssid === action.payload.bssid);
      if (entity) {
        entity.is_selected = true;
        selectedDevicesAdapter.addOne(state.selected, { ...entity, color: getRandomColor() });
      }
    },
    actionDeviceDeselect: (state, action: PayloadAction<{ bssid: string }>) => {
      const entity = state.data.list.find(item => item.bssid === action.payload.bssid);
      if (entity) {
        entity.is_selected = false;
      }
      selectedDevicesAdapter.removeOne(state.selected, action.payload.bssid);
    },
    actionDeviceSetSelectState: (state, action: PayloadAction<boolean>) => {
      const entities = state.data.list.filter(item => item.is_selected !== action.payload);
      const keys = entities.map(item => item.bssid);

      if (action.payload) {
        selectedDevicesAdapter.addMany(
          state.selected,
          entities.map(item => ({ ...item, color: getRandomColor() })),
        );
      } else {
        selectedDevicesAdapter.removeMany(state.selected, keys);
      }

      state.data.list = state.data.list.map(item => ({ ...item, is_selected: action.payload }));
    },
    actionDeviceClearSelectState: state => {
      selectedDevicesAdapter.removeAll(state.selected);
      state.data.list = state.data.list.map(item => ({ ...item, is_selected: false }));
    },

    /**
     * Color
     */
    actionUpdateColor: (state, action: PayloadAction<{ bssid: string; color: string }>) => {
      selectedDevicesAdapter.updateOne(state.selected, {
        id: action.payload.bssid,
        changes: { color: action.payload.color },
      });
    },
  },

  extraReducers: builder => {
    /**
     * List
     */
    builder.addCase(actionGetDeviceList.pending, state => {
      state.is_loading = true;
    });
    builder.addCase(actionGetDeviceList.fulfilled, (state, action) => {
      state.data.count = action.payload.count;
      state.data.list = action.payload.data.map(item => ({
        ...item,
        // selected entity key - bssid
        is_selected: state.selected.ids.includes(item.bssid),
      }));
      state.is_loading = false;

      // TODO: test
      // const entities = state.data.list.filter(item => state.selected.ids.includes(item.bssid));
      // selectedDevicesAdapter.upsertMany(state.selected, entities);
    });
    builder.addCase(actionGetDeviceList.rejected, state => {
      state.is_loading = false;
    });

    /**
     * Query
     */
    builder.addCase(actionDeviceSetPage.pending, (state, action) => {
      state.query.page = action.meta.arg;
    });
    builder.addCase(actionDeviceSetLimit.pending, (state, action) => {
      state.query.limit = action.meta.arg;
      state.query.page = 1;
    });
    builder.addCase(actionDeviceSetSearch.pending, (state, action) => {
      state.query.search = action.meta.arg || undefined; // to avoid empty string
      state.query.page = 1;
    });
    builder.addCase(actionDeviceSetColumn.pending, (state, action) => {
      state.query.column = action.meta.arg as DeviceListFilterType;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionDeviceSetOperator.pending, (state, action) => {
      state.query.operator = action.meta.arg as Operator;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionDeviceSetSort.pending, (state, action) => {
      state.query.direction =
        state.query.sort === action.meta.arg ? toggleDirection(state.query.direction) : 'asc';
      state.query.sort = state.query.direction
        ? (action.meta.arg as DeviceListSortType)
        : undefined;
    });

    /**
     * Clear
     */
    builder.addMatcher(
      action => action.type === actionPurgeAuthState.type,
      () => {
        return initialState;
      },
    );
  },
});

export const {
  actionDeviceSetColumnState,
  actionDeviceSetAllColumnsState,
  actionDeviceSelect,
  actionDeviceDeselect,
  actionDeviceSetSelectState,
  actionDeviceClearSelectState,
  actionUpdateColor,
} = deviceSlice.actions;
