import { axiosInstance } from '@api/axios.provider';
import { GetNotificationListParams } from './dto/get-notification-list.params';
import { NotificationListResponse } from './response/notification-list.response';

export async function apiGetNotificationList(
  params: GetNotificationListParams,
): Promise<NotificationListResponse> {
  const response = await axiosInstance.get<NotificationListResponse>('/api/v3/notifications', {
    params,
  });

  return response.data;
}
