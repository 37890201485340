import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SideBarWidth } from '@utils/enum/SidebarWidth';

const initialState: ThemeStoreState = {
  isLightTheme: true,
  sideBarWidth: SideBarWidth.medium,
  isSideBarOpen: false,
  isNavSideBarOpen: true,
};

export const themeSlice = createSlice({
  name: 'themeSlice',
  initialState,
  reducers: {
    actionToggleTheme: state => {
      state.isLightTheme = !state.isLightTheme;
    },
    actionSetSideBarOpen: state => {
      state.isSideBarOpen = true;
    },
    actionSetSideBarClose: state => {
      state.isSideBarOpen = false;
    },
    actionSetSideBarWidth: (state, action: PayloadAction<number>) => {
      state.sideBarWidth = action.payload;
    },
    actionToggleNavSidebar: state => {
      state.isNavSideBarOpen = !state.isNavSideBarOpen;
    },
  },
});

export const {
  actionToggleTheme,
  actionSetSideBarOpen,
  actionSetSideBarClose,
  actionSetSideBarWidth,
  actionToggleNavSidebar,
} = themeSlice.actions;

export type ThemeStoreState = {
  isLightTheme: boolean;
  sideBarWidth: number;
  isSideBarOpen: boolean;
  isNavSideBarOpen: boolean;
};
