import { apiGetEventList } from '@api/events/events.api';
import { EventListResponse } from '@api/events/response/event-list.response';
import { createErrorToast } from '@components/NotificationsHandler';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionPurgeAuthState } from '@store/auth/auth.slice';
import { EventStoreState } from './types';

const initialState: EventStoreState = {
  data: {
    list: [],
  },
  loading: false,
};

export const actionGetEventList = createAsyncThunk<
  EventListResponse,
  { from: string; to: string; devices?: string[] }
>('events/get-list', async ({ from, to, devices }, thunkAPI) => {
  try {
    const response = await apiGetEventList({
      from,
      to,
      devices,
    });

    return response;
  } catch (error) {
    createErrorToast(error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const eventSlice = createSlice({
  name: 'eventSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(actionGetEventList.pending, state => {
      state.loading = true;
    });
    builder.addCase(actionGetEventList.fulfilled, (state, action) => {
      state.data.list = action.payload;
      state.loading = false;
    });
    builder.addCase(actionGetEventList.rejected, state => {
      state.loading = false;
    });

    /**
     * Clear
     */
    builder.addMatcher(
      action => action.type === actionPurgeAuthState.type,
      () => {
        return initialState;
      },
    );
  },
});

export const {} = eventSlice.actions;
