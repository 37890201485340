export enum VolumeUnits {
  LITERS = 'l',
  GALLONS = 'gal',
}

export enum TemperatureUnits {
  CELSIUS = 'C',
  FAHRENHEIT = 'F',
}

export enum PressureUnits {
  PSI = 'psi',
  BAR = 'bar',
}

export enum ConcentrationUnits {
  PPM = 'ppm',
  GPG = 'gpg',
}

export enum FlowRateUnits {
  LPM = 'l/min',
  GPM = 'gal/min',
  FLOZPM = 'fl-oz/min',
}

export const BaseUnits = {
  Volume: VolumeUnits.LITERS,
  Temperature: TemperatureUnits.CELSIUS,
  Pressure: PressureUnits.BAR,
  Concentration: ConcentrationUnits.PPM,
  FlowRate: FlowRateUnits.LPM,
};
