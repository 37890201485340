import { axiosInstance } from '@api/axios.provider';
import { FileUploadBody } from '@api/common/dto/file-upload.body';
import { BssidResponse } from '@api/common/response/bssid.response';
import { DeviceBssidsDto } from './dto/device-bssids.dto';
import { GetDeviceListParams } from './dto/get-device-list.params';
import { GetDeviceParams } from './dto/get-device-params';
import { LockDevicesDto } from './dto/lock-devices.dto';
import { ResetDevicesDto } from './dto/reset-devices.dto';
import { UpdateDeviceFirmwareDto } from './dto/update-device-firmware.dto';
import { UpdateDeviceDto } from './dto/update-device.dto';
import { UpdateDevicesBypassSettingsDto } from './dto/update-devices-bypass-settings.dto';
import { UpdateDevicesCommercialModelDto } from './dto/update-devices-commercial-model.dto';
import { UpdateDevicesFiltrationSystemDto } from './dto/update-devices-filtration-system.dto';
import { UpdateDevicesFlushSettingsDto } from './dto/update-devices-flush-settings.dto';
import { UpdateDevicesIdleProtectionSettingsDto } from './dto/update-devices-idle-protection-settings.dto';
import { UpdateDevicesUVSettingsDto } from './dto/update-devices-uv-settings.dto';
import { DeviceListResponse } from './response/device-list.response';
import { DeviceShareCodeResponse } from './response/device-share-code.response';
import { DeviceResponse } from './response/device.response';
import { MultipleUpdateResponse } from './response/multiple-update.response';

export async function apiAddDevicesFromFile(payload: FileUploadBody): Promise<void> {
  const form = new FormData();
  form.append('file', payload.file);

  const response = await axiosInstance.post<void>('/api/v3/devices/file', form);

  return response.data;
}

export async function apiGetDeviceList(params: GetDeviceListParams): Promise<DeviceListResponse> {
  const response = await axiosInstance.get<DeviceListResponse>('/api/v3/devices', { params });

  return response.data;
}

export async function apiGetDevice(
  bssid: string,
  params?: GetDeviceParams,
): Promise<DeviceResponse> {
  const response = await axiosInstance.get<DeviceResponse>(`/api/v3/devices/${bssid}`, { params });

  return response.data;
}

export async function apiUpdateDevice(
  bssid: string,
  payload: UpdateDeviceDto,
): Promise<BssidResponse> {
  const response = await axiosInstance.put<BssidResponse>(`/api/v3/devices/${bssid}`, payload);

  return response.data;
}

export async function apiGenerateShareCode(bssid: string): Promise<DeviceShareCodeResponse> {
  const response = await axiosInstance.post<DeviceShareCodeResponse>(
    `/api/v3/devices/${bssid}/share`,
  );

  return response.data;
}

export async function apiUpdateDevicesFirmwareToLatestVersion(
  payload: DeviceBssidsDto,
): Promise<void> {
  const response = await axiosInstance.post<void>('/api/v3/devices/firmware/update', payload);

  return response.data;
}

export async function apiUpdateDeviceFirmware(
  bssid: string,
  payload: UpdateDeviceFirmwareDto,
): Promise<void> {
  const response = await axiosInstance.post<void>(
    `/api/v3/devices/${bssid}/firmware/update`,
    payload,
  );

  return response.data;
}

export async function apiUpdateDevicesCommercialModel(
  payload: UpdateDevicesCommercialModelDto,
): Promise<MultipleUpdateResponse> {
  const response = await axiosInstance.put<MultipleUpdateResponse>(
    '/api/v3/devices/model',
    payload,
  );

  return response.data;
}

export async function apiUpdateDevicesFiltrationSystem(
  payload: UpdateDevicesFiltrationSystemDto,
): Promise<MultipleUpdateResponse> {
  const response = await axiosInstance.put<MultipleUpdateResponse>(
    '/api/v3/devices/filtration-system',
    payload,
  );

  return response.data;
}

export async function apiUpdateDevicesFlushSettings(
  payload: UpdateDevicesFlushSettingsDto,
): Promise<void> {
  const response = await axiosInstance.put<void>('/api/v3/devices/settings/flush', payload);

  return response.data;
}

export async function apiUpdateDevicesIdleProtectionSettings(
  payload: UpdateDevicesIdleProtectionSettingsDto,
): Promise<MultipleUpdateResponse> {
  const response = await axiosInstance.put<MultipleUpdateResponse>(
    '/api/v3/devices/settings/idle-protection',
    payload,
  );

  return response.data;
}

export async function apiUpdateDevicesBypassSettings(
  payload: UpdateDevicesBypassSettingsDto,
): Promise<MultipleUpdateResponse> {
  const response = await axiosInstance.put<MultipleUpdateResponse>(
    '/api/v3/devices/settings/bypass',
    payload,
  );

  return response.data;
}

export async function apiUpdateDevicesUVSettings(
  payload: UpdateDevicesUVSettingsDto,
): Promise<MultipleUpdateResponse> {
  const response = await axiosInstance.put<MultipleUpdateResponse>(
    '/api/v3/devices/settings/uv',
    payload,
  );

  return response.data;
}

export async function apiFlushDevices(payload: DeviceBssidsDto): Promise<void> {
  const response = await axiosInstance.post('/api/v3/devices/flush', payload);
  return response.data;
}

export async function apiLockDevices(payload: LockDevicesDto): Promise<void> {
  const response = await axiosInstance.put<void>('/api/v3/devices/lock', payload);

  return response.data;
}

export async function apiResetDevices(payload: ResetDevicesDto): Promise<void> {
  const response = await axiosInstance.post<void>('/api/v3/devices/reset', payload);

  return response.data;
}

export async function apiRebootDevices(payload: DeviceBssidsDto): Promise<void> {
  const response = await axiosInstance.post<void>('/api/v3/devices/reboot', payload);

  return response.data;
}

export async function apiDeleteDevices(payload: DeviceBssidsDto): Promise<void> {
  const response = await axiosInstance.delete<void>('/api/v3/devices', { data: payload });

  return response.data;
}
