import { apiLogin } from '@api/auth/auth.api';
import { LoginBody } from '@api/auth/body/login.body';
import { LoginResponse } from '@api/auth/response/login.response';
import { createErrorToast } from '@components/NotificationsHandler';
import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit';
import { Role } from '@utils/enum/Role';

const initialState: AuthStoreState = {
  access_token: null,
  refresh_token: null,
  user_id: null,
  user_role: null,
};

const createSliceWithThunks = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

export const authSlice = createSliceWithThunks({
  name: 'auth',
  initialState,
  reducers: create => ({
    actionLogin: create.asyncThunk<LoginResponse, LoginBody>(
      async (body, thunkAPI) => {
        try {
          const response = await apiLogin(body);
          return response;
        } catch (err) {
          createErrorToast(err);
          return thunkAPI.rejectWithValue(null);
        }
      },
      {
        fulfilled: (state, action) => {
          state.access_token = action.payload.access_token;
          state.refresh_token = action.payload.refresh_token;
          state.user_id = action.payload.user_id;
          state.user_role = action.payload.user_role;
        },
      },
    ),

    actionSetAuthState: create.reducer<AuthStoreState>((state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      state.user_id = action.payload.user_id;
      state.user_role = action.payload.user_role;
    }),

    actionPurgeAuthState: create.reducer<void>(() => {
      return initialState;
    }),
  }),
});

export const { actionLogin, actionSetAuthState, actionPurgeAuthState } = authSlice.actions;

export type AuthStoreState = {
  access_token: string | null;
  refresh_token: string | null;
  user_id: string | null;
  user_role: Role | null;
};
