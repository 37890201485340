import { useAppDispatch, useAppSelector } from '@/hooks/store.hooks';
import { apiLogout } from '@api/auth/auth.api';
import { AccountCircle } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { actionPurgeAuthState } from '@store/auth/auth.slice';
import { actionToggleNavSidebar, actionToggleTheme } from '@store/theme/theme.slice';
import { Role } from '@utils/enum/Role';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ThemeSwitch } from '../components/ThemeSwitch';

export const Appbar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme<Theme>();

  const userRole = useAppSelector(state => state.auth.user_role);
  const isLightTheme = useAppSelector(state => state.theme.isLightTheme);

  const [checked, setChecked] = useState(!isLightTheme);
  const [accountMenuAnchorNode, setAccountMenuAnchorNode] = useState<HTMLElement | null>(null);

  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAccountMenuAnchorNode(event.currentTarget);
  };
  const handleAccountMenuClose = (): void => {
    setAccountMenuAnchorNode(null);
  };

  const toggleTheme = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
    dispatch(actionToggleTheme());
  };

  return (
    <AppBar
      position="static"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: 64,
        boxShadow: 'none',
        ['.MuiToolbar-root']: {
          paddingLeft: '8px',
        },
      }}
      style={{
        backgroundColor:
          theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.common.black,
        // backgroundImage: 'none',
      }}
    >
      <Toolbar sx={{ flex: 1 }}>
        <IconButton
          color="inherit"
          title={t('appbar.menu.title')}
          aria-label={t('appbar.menu.title')}
          onClick={() => {
            dispatch(actionToggleNavSidebar());
          }}
        >
          <MenuIcon />
        </IconButton>

        <Typography variant="h6" component="div" noWrap sx={{ flexGrow: 1, mr: 2, ml: 2 }}>
          {t('appbar.title')}
        </Typography>

        <ThemeSwitch checked={checked} value={!isLightTheme} onChange={toggleTheme} />

        <IconButton
          color="inherit"
          title={t('appbar.settings.title')}
          aria-label={t('appbar.settings.title')}
          onClick={() => {
            navigate('/settings');
          }}
        >
          <SettingsIcon />
        </IconButton>

        <IconButton
          color="inherit"
          title={t('appbar.account_menu.title')}
          aria-label={t('appbar.account_menu.title')}
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleAccountMenuOpen}
        >
          <AccountCircle />
        </IconButton>

        <Menu
          id="menu-appbar"
          anchorEl={accountMenuAnchorNode}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(accountMenuAnchorNode)}
          onClose={handleAccountMenuClose}
        >
          {userRole !== Role.Superadmin && (
            <MenuItem
              onClick={() => {
                navigate('/profile');
                handleAccountMenuClose();
              }}
            >
              {t('appbar.account_menu.items.profile')}
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              apiLogout()
                .catch(() => {
                  // do nothing
                })
                .finally(() => {
                  dispatch(actionPurgeAuthState());
                });
              handleAccountMenuClose();
            }}
          >
            {t('appbar.account_menu.items.logout')}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
