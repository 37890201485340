import { axiosInstance } from '@api/axios.provider';
import { AxiosResponse } from 'axios';
import { ForgotPasswordBody } from './body/forgot-password.body';
import { LoginBody } from './body/login.body';
import { RestorePasswordBody } from './body/restore-password.body';
import { LoginResponse } from './response/login.response';
import { RefreshResponse } from './response/refresh.response';

export async function apiLogin(payload: LoginBody): Promise<LoginResponse> {
  const response = await axiosInstance.post<LoginResponse, AxiosResponse<LoginResponse>, LoginBody>(
    '/api/v3/auth/login/dashboard',
    payload,
  );

  return response.data;
}

export async function apiLogout(): Promise<void> {
  const response = await axiosInstance.post<void, AxiosResponse<void>, void>('/api/v3/auth/logout');

  return response.data;
}

export async function apiRefresh(token?: string | null): Promise<RefreshResponse> {
  const response = await axiosInstance.post<RefreshResponse>(
    '/api/v3/auth/refresh',
    {},
    {
      headers: {
        'refresh-token': token,
      },
    },
  );

  return response.data;
}

export async function apiForgotPassword(payload: ForgotPasswordBody): Promise<void> {
  const response = await axiosInstance.post<void, AxiosResponse<void>, ForgotPasswordBody>(
    '/api/v3/auth/forgot-password/dashboard',
    payload,
  );

  return response.data;
}

export async function apiRestorePassword(
  payload: RestorePasswordBody,
  token: string,
): Promise<void> {
  const response = await axiosInstance.post<void, AxiosResponse<void>, RestorePasswordBody>(
    '/api/v3/auth/password/restore',
    payload,
    {
      headers: {
        'password-recovery-token': token,
      },
    },
  );

  return response.data;
}
