import { axiosInstance } from '@api/axios.provider';
import { ChangeStatusDto } from '@api/common/dto/change-status.dto';
import { ObjectIdResponse } from '@api/common/response/object-id.response';
import { GetUserListParams } from './dto/get-user-list.params';
import { UpdateUserDto } from './dto/update-user.dto';
import { UserListResponse } from './response/user-list.response';
import { UserResponse } from './response/user.response';

export async function apiGetUserList(params: GetUserListParams): Promise<UserListResponse> {
  const response = await axiosInstance.get<UserListResponse>('/api/v3/users', { params });

  return response.data;
}

export async function apiGetUser(id: string): Promise<UserResponse> {
  const response = await axiosInstance.get<UserResponse>(`/api/v3/users/${id}`);

  return response.data;
}

export async function apiUpdateUser(id: string, payload: UpdateUserDto): Promise<ObjectIdResponse> {
  const response = await axiosInstance.put<ObjectIdResponse>(`/api/v3/users/${id}`, payload);

  return response.data;
}

export async function apiChangeUserStatus(
  id: string,
  payload: ChangeStatusDto,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.put<ObjectIdResponse>(
    `/api/v3/users/${id}/change-status`,
    payload,
  );

  return response.data;
}

export async function apiDeleteUser(id: string): Promise<void> {
  const response = await axiosInstance.delete<void>(`/api/v3/users/${id}`);

  return response.data;
}
