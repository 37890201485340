import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ConcentrationUnits,
  PressureUnits,
  TemperatureUnits,
  VolumeUnits,
} from '@utils/converter/units';

const initialState: SettingsStoreState = {
  volume: VolumeUnits.LITERS,
  temperature: TemperatureUnits.CELSIUS,
  pressure: PressureUnits.BAR,
  concentration: ConcentrationUnits.PPM,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    actionSetVolumeUnit: (state, action: PayloadAction<VolumeUnits>) => {
      state.volume = action.payload;
    },

    actionSetTemperatureUnit: (state, action: PayloadAction<TemperatureUnits>) => {
      state.temperature = action.payload;
    },

    actionSetPressureUnit: (state, action: PayloadAction<PressureUnits>) => {
      state.pressure = action.payload;
    },

    actionSetConcentrationUnit: (state, action: PayloadAction<ConcentrationUnits>) => {
      state.concentration = action.payload;
    },
  },
});

export const {
  actionSetVolumeUnit,
  actionSetTemperatureUnit,
  actionSetPressureUnit,
  actionSetConcentrationUnit,
} = settingsSlice.actions;

export type SettingsStoreState = {
  volume: VolumeUnits;
  temperature: TemperatureUnits;
  pressure: PressureUnits;
  concentration: ConcentrationUnits;
};
