import 'react-toastify/dist/ReactToastify.css';

import { isAxiosError } from 'axios';
import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useAppSelector } from '../hooks/store.hooks';

export const createErrorToast = (error: unknown): void => {
  if (isAxiosError(error)) {
    if (Array.isArray(error.response?.data?.message)) {
      toast.error(error.response?.data?.message.join(','));
    }

    toast.error(error.response?.data?.message);
  } else {
    if (error instanceof Error) {
      toast.error(error.message);
    }
  }
};

export const createToastNotification = (
  type: 'info' | 'success' | 'dark' | 'warning',
  text: string,
): void => {
  if (type === 'info') {
    toast.info(text);
  }
  if (type === 'success') {
    toast.success(text);
  }
  if (type === 'warning') {
    toast.warning(text);
  }
  if (type === 'dark') {
    toast.dark(text);
  }
};

export const NotificationsHandler: React.FC = () => {
  const isLightTheme = useAppSelector(state => state.theme.isLightTheme);

  return (
    <ToastContainer
      position="bottom-left"
      autoClose={4000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={isLightTheme ? 'light' : 'dark'}
    />
  );
};
