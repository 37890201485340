import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionPurgeAuthState } from '@store/auth/auth.slice';
import { Range } from '@utils/charts/chart.types';

const chartsInitialState: ChartsStoreState = {
  range: Range.Day,
  hidden_charts: [],
  aggregated_data: false,
};

export const chartsSlice = createSlice({
  name: 'charts',
  initialState: chartsInitialState,
  reducers: {
    actionSetRange: (state, action: PayloadAction<Range>) => {
      state.range = action.payload;
    },

    actionSetAggregatedData: (state, action: PayloadAction<boolean>) => {
      state.aggregated_data = action.payload;
    },
  },

  extraReducers: builder => {
    /**
     * Clear
     */
    builder.addMatcher(
      action => action.type === actionPurgeAuthState.type,
      () => {
        return chartsInitialState;
      },
    );
  },
});

export const { actionSetRange, actionSetAggregatedData } = chartsSlice.actions;

export type ChartsStoreState = {
  range: Range;
  hidden_charts: [];
  aggregated_data: boolean;
};
