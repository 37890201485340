import { actionPurgeAuthState, actionSetAuthState } from '@store/auth/auth.slice';
import { store } from '@store/index';
import axios, { AxiosError } from 'axios';
import React from 'react';
import { apiRefresh } from './auth/auth.api';

export const axiosInstance = axios.create({ baseURL: process.env.API_URL || '/' });

export const AxiosProvider: React.FC = () => {
  let refreshState = false;
  let requestQueue: Array<(token: string) => void> = [];

  function addToQueue(callback: (token: string) => void): void {
    requestQueue.push(callback);
  }

  function executeQueue(token: string): void {
    requestQueue.forEach(callback => callback(token));
  }

  axiosInstance.interceptors.request.use(config => {
    config.headers['access-token'] = store.getState().auth.access_token;
    return config;
  });

  axiosInstance.interceptors.response.use(
    response => response,
    async (error: AxiosError) => {
      const _config = error.config;

      if (_config?.url === '/api/v3/auth/refresh') {
        store.dispatch(actionPurgeAuthState());
        return;
      }

      if (_config && error.response?.status === 401) {
        if (!refreshState) {
          refreshState = true;

          apiRefresh(store.getState().auth.refresh_token)
            .then(response => {
              store.dispatch(actionSetAuthState(response));
              executeQueue(response.access_token);
              refreshState = false;
              requestQueue = [];
            })
            .catch(() => {
              refreshState = false;
              requestQueue = [];
            });
        }

        return new Promise(resolve =>
          addToQueue(token => {
            _config.headers['access-token'] = token;
            resolve(axiosInstance(_config));
          }),
        );
      }

      return Promise.reject(error);
    },
  );

  return null;
};
