import { Operator } from '@api/common/dto/search.params.dto';
import { apiGetDeviceModelList } from '@api/device-models/device-models.api';
import { DeviceModelListResponse } from '@api/device-models/response/device-model-list.response';
import { createErrorToast } from '@components/NotificationsHandler';
import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { actionPurgeAuthState } from '@store/auth/auth.slice';
import { RootState } from '@store/index';
import { toggleDirection } from '@utils/common';
import { AllColumnsStatePayload, ColumnStatePayload } from '@utils/types/column';
import {
  DeviceModelFilterType,
  DeviceModelSortType,
  DeviceModelStoreState,
  DeviceModelWithSelect,
} from './types';

export const selectedDeviceModelsAdapter = createEntityAdapter<DeviceModelWithSelect, string>({
  selectId: entity => entity._id,
});

/**
 * List
 */
export const actionGetDeviceModelList = createAsyncThunk<
  DeviceModelListResponse,
  void,
  { state: RootState }
>('devices/models/get-list', async (_, thunkAPI) => {
  const { page, limit, search, column, operator, sort, direction } =
    thunkAPI.getState().deviceModels.query;
  const offset = (page - 1) * limit;

  try {
    return await apiGetDeviceModelList({
      offset,
      limit,
      search,
      column,
      operator,
      sort,
      direction,
    });
  } catch (error) {
    createErrorToast(error);
    return thunkAPI.rejectWithValue(error);
  }
});

/**
 * Query
 */
export const actionDeviceModelSetPage = createAsyncThunk<void, number, { state: RootState }>(
  'devices/models/set-page',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetDeviceModelList());
  },
);

export const actionDeviceModelSetLimit = createAsyncThunk<void, number, { state: RootState }>(
  'devices/models/set-limit',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetDeviceModelList());
  },
);

export const actionDeviceModelSetSearch = createAsyncThunk<
  void,
  string | undefined,
  { state: RootState }
>('devices/models/set-search', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetDeviceModelList());
});

export const actionDeviceModelSetColumn = createAsyncThunk<void, string, { state: RootState }>(
  'devices/models/set-column',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().deviceModels.query.search) {
      thunkAPI.dispatch(actionGetDeviceModelList());
    }
  },
);

export const actionDeviceModelSetOperator = createAsyncThunk<void, string, { state: RootState }>(
  'devices/models/set-operator',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().deviceModels.query.search) {
      thunkAPI.dispatch(actionGetDeviceModelList());
    }
  },
);

export const actionDeviceModelSetSort = createAsyncThunk<
  void,
  string | undefined,
  { state: RootState }
>('devices/models/set-sort', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetDeviceModelList());
});

const initialState: DeviceModelStoreState = {
  data: {
    count: 0,
    list: [],
  },
  selected: selectedDeviceModelsAdapter.getInitialState(),
  query: {
    page: 1,
    limit: 20,
    search: undefined,
    column: 'name',
    operator: 'co',
    sort: undefined,
    direction: undefined,
  },
  hidden_columns: [],
  is_loading: false,
};

export const deviceModelSlice = createSlice({
  name: 'deviceModelSlice',
  initialState,
  reducers: {
    /**
     * Columns
     */
    actionDeviceModelSetColumnState: (state, action: PayloadAction<ColumnStatePayload>) => {
      const set = new Set(state.hidden_columns);
      if (action.payload.is_visible) {
        set.delete(action.payload.label);
      } else {
        set.add(action.payload.label);
      }

      state.hidden_columns = Array.from(set);
    },
    actionDeviceModelSetAllColumnsState: (state, action: PayloadAction<AllColumnsStatePayload>) => {
      if (action.payload.is_visible) {
        state.hidden_columns = [];
      } else {
        state.hidden_columns = action.payload.labels;
      }
    },

    /**
     * Select
     */
    actionDeviceModelSelect: (state, action: PayloadAction<{ _id: string }>) => {
      const entity = state.data.list.find(item => item._id === action.payload._id);
      if (entity) {
        entity.is_selected = true;
        selectedDeviceModelsAdapter.addOne(state.selected, entity);
      }
    },
    actionDeviceModelDeselect: (state, action: PayloadAction<{ _id: string }>) => {
      const entity = state.data.list.find(item => item._id === action.payload._id);
      if (entity) {
        entity.is_selected = false;
      }
      selectedDeviceModelsAdapter.removeOne(state.selected, action.payload._id);
    },
    actionDeviceModelSetSelectState: (state, action: PayloadAction<boolean>) => {
      const entities = state.data.list.filter(item => item.is_selected !== action.payload);
      const keys = entities.map(item => item._id);

      if (action.payload) {
        selectedDeviceModelsAdapter.addMany(state.selected, entities);
      } else {
        selectedDeviceModelsAdapter.removeMany(state.selected, keys);
      }

      state.data.list = state.data.list.map(item => ({ ...item, is_selected: action.payload }));
    },
    actionDeviceModelClearSelectState: state => {
      selectedDeviceModelsAdapter.removeAll(state.selected);
      state.data.list = state.data.list.map(item => ({ ...item, is_selected: false }));
    },
  },

  extraReducers: builder => {
    /**
     * List
     */
    builder.addCase(actionGetDeviceModelList.pending, state => {
      state.is_loading = true;
    });
    builder.addCase(actionGetDeviceModelList.fulfilled, (state, action) => {
      state.data.count = action.payload.count;
      state.data.list = action.payload.data.map(item => ({
        ...item,
        is_selected: state.selected.ids.includes(item._id),
      }));
      state.is_loading = false;
    });
    builder.addCase(actionGetDeviceModelList.rejected, state => {
      state.is_loading = false;
    });

    /**
     * Query
     */
    builder.addCase(actionDeviceModelSetPage.pending, (state, action) => {
      state.query.page = action.meta.arg;
    });
    builder.addCase(actionDeviceModelSetLimit.pending, (state, action) => {
      state.query.limit = action.meta.arg;
      state.query.page = 1;
    });
    builder.addCase(actionDeviceModelSetSearch.pending, (state, action) => {
      state.query.search = action.meta.arg || undefined; // to avoid empty string
      state.query.page = 1;
    });
    builder.addCase(actionDeviceModelSetColumn.pending, (state, action) => {
      state.query.column = action.meta.arg as DeviceModelFilterType;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionDeviceModelSetOperator.pending, (state, action) => {
      state.query.operator = action.meta.arg as Operator;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionDeviceModelSetSort.pending, (state, action) => {
      state.query.direction =
        state.query.sort === action.meta.arg ? toggleDirection(state.query.direction) : 'asc';
      state.query.sort = state.query.direction
        ? (action.meta.arg as DeviceModelSortType)
        : undefined;
    });

    /**
     * Clear
     */
    builder.addMatcher(
      action => action.type === actionPurgeAuthState.type,
      () => {
        return initialState;
      },
    );
  },
});

export const {
  actionDeviceModelSetColumnState,
  actionDeviceModelSetAllColumnsState,
  actionDeviceModelSelect,
  actionDeviceModelDeselect,
  actionDeviceModelSetSelectState,
  actionDeviceModelClearSelectState,
} = deviceModelSlice.actions;
