import CircularProgress from '@mui/material/CircularProgress';
import { Role } from '@utils/enum/Role';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useQuery } from '../hooks/useQuery';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

const LoginScreen = React.lazy(() => import(/* webpackChunkName: "login" */ '@scene/Login/Login'));

const DevicesScreen = React.lazy(
  () => import(/* webpackChunkName: "devices" */ '@scene/Devices/DevicesScreen'),
);
const DeviceScreen = React.lazy(
  () => import(/* webpackChunkName: "device" */ '@scene/Device/DeviceScreen'),
);

const DeviceModelsScreen = React.lazy(
  () => import(/* webpackChunkName: "deviceModels" */ '@scene/DeviceModels/DeviceModelsScreen'),
);

const CartridgeTypesScreen = React.lazy(
  () =>
    import(/* webpackChunkName: "cartridgeTypes" */ '@scene/CartridgeTypes/CartridgeTypesScreen'),
);

const FiltrationSystemsScreen = React.lazy(
  () =>
    import(
      /* webpackChunkName: "filtrationSystems" */ '@scene/FiltrationSystems/FiltrationSystemsScreen'
    ),
);

const FiltrationSystemGroupsScreen = React.lazy(
  () =>
    import(
      /* webpackChunkName: "filtrationSystemGroups" */ '@scene/FiltrationSystemGroups/FiltrationSystemGroupsScreen'
    ),
);

const AccountsAdmin = React.lazy(
  () => import(/* webpackChunkName: "accountsAdmin" */ '@scene/Accounts/AccountsAdmin'),
);
const AccountsVendor = React.lazy(
  () => import(/* webpackChunkName: "accountsVendor" */ '@scene/Accounts/AccountsVendor'),
);
const AccountsUser = React.lazy(
  () => import(/* webpackChunkName: "accountsUser" */ '@scene/Accounts/AccountsUser'),
);

const CalendarScreen = React.lazy(
  () => import(/* webpackChunkName: "calendar" */ '@scene/Calendar/CalendarScreen'),
);
const ChartsScreen = React.lazy(
  () => import(/* webpackChunkName: "charts" */ '@scene/Charts/ChartsScreen'),
);
const Notifications = React.lazy(
  () => import(/* webpackChunkName: "notifications" */ '@scene/Notifications/Notifications'),
);

const Profile = React.lazy(
  () => import(/* webpackChunkName: "profile" */ '@scene/Profile/Profile'),
);

const NotFound = React.lazy(
  () => import(/* webpackChunkName: "notFound" */ '@components/NotFound'),
);

const ForgotPassword = React.lazy(
  () => import(/* webpackChunkName: "forgotPassword" */ '@scene/ForgotPassword/ForgotPassword'),
);

const ConfirmEmail = React.lazy(
  () => import(/* webpackChunkName: "confirmEmail" */ '@scene/ConfirmEmail/ConfirmEmail'),
);
const RestorePassword = React.lazy(
  () => import(/* webpackChunkName: "restorePassword" */ '@scene/RestorePassword/RestorePassword'),
);

const SettingsScreen = React.lazy(
  () => import(/* webpackChunkName: "settings" */ '@scene/Settings/SettingsScreen'),
);

export const Router: React.FC = () => {
  const query = useQuery();

  return (
    <Suspense
      fallback={
        <CircularProgress color="primary" sx={{ position: 'absolute', alignSelf: 'center' }} />
      }
    >
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/forgot" element={<ForgotPassword />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/profile" element={<Profile />} />
        </Route>

        <Route element={<PrivateRoute roles={[Role.Superadmin]} />}>
          <Route path="/admins" element={<AccountsAdmin />} />
        </Route>

        <Route element={<PrivateRoute roles={[Role.Admin]} />}>
          <Route path="/filtration-systems" element={<FiltrationSystemsScreen />} />
          <Route path="/filtration-system-groups" element={<FiltrationSystemGroupsScreen />} />
          <Route path="/cartridge-types" element={<CartridgeTypesScreen />} />
          <Route path="/vendors" element={<AccountsVendor />} />
          <Route path="/users" element={<AccountsUser />} />
        </Route>

        <Route element={<PrivateRoute roles={[Role.Admin, Role.Vendor]} />}>
          <Route path="/devices" element={<DevicesScreen />} />
          <Route path="/devices/models" element={<DeviceModelsScreen />} />
          <Route path="/devices/:bssid" element={<DeviceScreen />} />
          <Route path="/calendar" element={<CalendarScreen />} />
          <Route path="/charts" element={<ChartsScreen />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/settings" element={<SettingsScreen />} />
        </Route>

        <Route path="/email/confirm/" element={<ConfirmEmail token={query.get('token')} />} />
        <Route path="/password/restore/" element={<RestorePassword token={query.get('token')} />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
