export enum Range {
  Day,
  Week,
  Month,
  Year,
}

export type ChartData = {
  data: {
    x: Date;
    y: number;
  }[];
  source: string;
}[];

export type ChartListProps = {
  statistics: {
    data: {
      input: {
        volume: number;
        tds: number;
      };
      output: {
        volume: number;
        tds: number;
      };
      drain: number;
      temperature: number;
      pressure: number;
      production_rate: number;
      rejection_rate: number;
      recovery_rate: number;
      instant_recovery_rate: number;
      x: Date;
    }[];
    source: string;
  }[];
  range: Range;
  xAxisDomain: AxisDomain;
  xAxisOptions: AxisOptions;
};

export type AxisDomain = {
  min: number;
  max: number;
};
export type AxisOptions = {
  tickCount: number;
  barWidth: number;
  dx: number;
  labelFormat: string;
};
