import { PersistConfig, createMigrate } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { AdminStoreState } from './admin/types';
import { AuthStoreState } from './auth/auth.slice';
import { CartridgeTypeStoreState } from './cartridge-type/types';
import { ChartsStoreState } from './chart/charts.slice';
import { DeviceModelStoreState } from './device-model/types';
import { DeviceStoreState } from './device/types';
import { EventStoreState } from './event/types';
import { FiltrationSystemGroupStoreState } from './filtration-system-group/types';
import { FiltrationSystemStoreState } from './filtration-system/types';
import { NotificationStoreState } from './notification/types';
import { SettingsStoreState } from './settings/settings.slice';
import { ThemeStoreState } from './theme/theme.slice';
import { UserStoreState } from './user/types';
import { VendorStoreState } from './vendor/types';

export const authConfig: PersistConfig<AuthStoreState> = {
  key: 'auth',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

export const themeConfig: PersistConfig<ThemeStoreState> = {
  key: 'theme',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

export const deviceConfig: PersistConfig<DeviceStoreState> = {
  key: 'device',
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate(
    {
      1: state => {
        if (state) {
          return {
            _persist: state._persist,
          };
        }
      },
    },
    { debug: false },
  ),
};

export const deviceModelsConfig: PersistConfig<DeviceModelStoreState> = {
  key: 'deviceModels',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

export const cartridgeTypesConfig: PersistConfig<CartridgeTypeStoreState> = {
  key: 'cartridgeTypes',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

export const filtrationSystemsConfig: PersistConfig<FiltrationSystemStoreState> = {
  key: 'filtrationSystems',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

export const filtrationSystemGroupsConfig: PersistConfig<FiltrationSystemGroupStoreState> = {
  key: 'filtrationSystemGroups',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

export const adminsConfig: PersistConfig<AdminStoreState> = {
  key: 'admins',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

export const vendorsConfig: PersistConfig<VendorStoreState> = {
  key: 'vendors',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

export const usersConfig: PersistConfig<UserStoreState> = {
  key: 'users',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

export const eventsConfig: PersistConfig<EventStoreState> = {
  key: 'events',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

export const chartsConfig: PersistConfig<ChartsStoreState> = {
  key: 'charts',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

export const notificationsConfig: PersistConfig<NotificationStoreState> = {
  key: 'notifications',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
  whitelist: ['types'],
};

export const settingsConfig: PersistConfig<SettingsStoreState> = {
  key: 'settings',
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};
