import { Direction } from '@api/common/dto/search.params.dto';

export function sleep(ms: number): Promise<unknown> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const toggleDirection = (value: Direction | undefined): Direction | undefined => {
  if (value === undefined) {
    return 'asc';
  }
  if (value === 'asc') {
    return 'desc';
  }
  if (value === 'desc') {
    return undefined;
  }
};

export const getRandomColor = (): string => {
  const value = Math.random().toString(16).slice(-6);
  return `#${value}`;
};

export const round = (num: number, precision?: number): number => {
  const scale = Math.pow(10, Math.abs(precision || 0));
  return Math.round((num + Number.EPSILON) * scale) / scale;
};
