import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { adminSlice } from './admin/admin.slice';
import { AdminStoreState } from './admin/types';
import { AuthStoreState, authSlice } from './auth/auth.slice';
import { cartridgeTypeSlice } from './cartridge-type/cartridge-type.slice';
import { CartridgeTypeStoreState } from './cartridge-type/types';
import { ChartsStoreState, chartsSlice } from './chart/charts.slice';
import { deviceModelSlice } from './device-model/device-model.slice';
import { DeviceModelStoreState } from './device-model/types';
import { deviceSlice } from './device/device.slice';
import { DeviceStoreState } from './device/types';
import { eventSlice } from './event/events.slice';
import { EventStoreState } from './event/types';
import { filtrationSystemGroupSlice } from './filtration-system-group/filtration-system-group.slice';
import { FiltrationSystemGroupStoreState } from './filtration-system-group/types';
import { filtrationSystemSlice } from './filtration-system/filtration-system.slice';
import { FiltrationSystemStoreState } from './filtration-system/types';
import { notificationSlice } from './notification/notification.slice';
import { NotificationStoreState } from './notification/types';
import {
  adminsConfig,
  authConfig,
  cartridgeTypesConfig,
  chartsConfig,
  deviceConfig,
  deviceModelsConfig,
  eventsConfig,
  filtrationSystemGroupsConfig,
  filtrationSystemsConfig,
  notificationsConfig,
  settingsConfig,
  themeConfig,
  usersConfig,
  vendorsConfig,
} from './persist.config';
import { SettingsStoreState, settingsSlice } from './settings/settings.slice';
import { ThemeStoreState, themeSlice } from './theme/theme.slice';
import { UserStoreState } from './user/types';
import { userSlice } from './user/user.slice';
import { VendorStoreState } from './vendor/types';
import { vendorSlice } from './vendor/vendor.slice';

const __DEV__ = process.env.NODE_ENV !== 'production';

const rootReducer = combineReducers({
  auth: persistReducer<AuthStoreState>(authConfig, authSlice.reducer),
  theme: persistReducer<ThemeStoreState>(themeConfig, themeSlice.reducer),
  devices: persistReducer<DeviceStoreState>(deviceConfig, deviceSlice.reducer),
  deviceModels: persistReducer<DeviceModelStoreState>(deviceModelsConfig, deviceModelSlice.reducer),
  cartridgeTypes: persistReducer<CartridgeTypeStoreState>(
    cartridgeTypesConfig,
    cartridgeTypeSlice.reducer,
  ),
  filtrationSystems: persistReducer<FiltrationSystemStoreState>(
    filtrationSystemsConfig,
    filtrationSystemSlice.reducer,
  ),
  filtrationSystemGroups: persistReducer<FiltrationSystemGroupStoreState>(
    filtrationSystemGroupsConfig,
    filtrationSystemGroupSlice.reducer,
  ),
  admins: persistReducer<AdminStoreState>(adminsConfig, adminSlice.reducer),
  vendors: persistReducer<VendorStoreState>(vendorsConfig, vendorSlice.reducer),
  users: persistReducer<UserStoreState>(usersConfig, userSlice.reducer),
  events: persistReducer<EventStoreState>(eventsConfig, eventSlice.reducer),
  charts: persistReducer<ChartsStoreState>(chartsConfig, chartsSlice.reducer),
  notifications: persistReducer<NotificationStoreState>(
    notificationsConfig,
    notificationSlice.reducer,
  ),
  settings: persistReducer<SettingsStoreState>(settingsConfig, settingsSlice.reducer),
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: __DEV__,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: __DEV__
        ? {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          }
        : false,
      immutableCheck: __DEV__ ? true : false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
