import { axiosInstance } from '@api/axios.provider';
import { FileUploadBody } from '@api/common/dto/file-upload.body';
import { ObjectIdsDto } from '@api/common/dto/object-ids.dto';
import { ObjectIdResponse } from '@api/common/response/object-id.response';
import { AssignFiltrationSystemsToVendorBody } from './dto/assign-filtration-systems-to-vendor.body';
import { GetFiltrationSystemListParams } from './dto/get-filtration-system-list.params';
import { GetFiltrationSystemsByGroupParams } from './dto/get-filtration-systems-by-group.params';
import { UpdateFiltrationSystemBody } from './dto/update-filtration-system.body';
import { FiltrationSystemListResponse } from './response/filtration-system-list.response';
import { FiltrationSystemsByGroupResponse } from './response/filtration-systems-by-group.response';

export async function apiCreateFiltrationSystemFromFile(payload: FileUploadBody): Promise<void> {
  const form = new FormData();
  form.append('file', payload.file);

  const response = await axiosInstance.post<void>('/api/v3/filtration-systems/file', form);

  return response.data;
}

export async function apiGetFiltrationSystemList(
  params: GetFiltrationSystemListParams,
): Promise<FiltrationSystemListResponse> {
  const response = await axiosInstance.get<FiltrationSystemListResponse>(
    '/api/v3/filtration-systems',
    {
      params,
    },
  );

  return response.data;
}

export async function apiGetFiltrationSystemsByGroup(
  params: GetFiltrationSystemsByGroupParams,
): Promise<FiltrationSystemsByGroupResponse> {
  const response = await axiosInstance.get<FiltrationSystemsByGroupResponse>(
    '/api/v3/filtration-systems/groups',
    {
      params,
    },
  );

  return response.data;
}

export async function apiAssignFiltrationSystemsToVendor(
  payload: AssignFiltrationSystemsToVendorBody,
): Promise<void> {
  const response = await axiosInstance.put<void>('/api/v3/filtration-systems/vendor', payload);

  return response.data;
}

export async function apiUpdateFiltrationSystem(
  id: string,
  payload: UpdateFiltrationSystemBody,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.put<ObjectIdResponse>(
    `/api/v3/filtration-systems/${id}`,
    payload,
  );

  return response.data;
}

export async function apiDeleteFiltrationSystems(body: ObjectIdsDto): Promise<void> {
  const response = await axiosInstance.delete<void>('/api/v3/filtration-systems', {
    data: body,
  });

  return response.data;
}
