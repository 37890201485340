import { useAppSelector } from '@/hooks/store.hooks';
import { Role } from '@utils/enum/Role';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface Props {
  roles?: Role[];
}

export const PrivateRoute: React.FC<Props> = ({ roles }) => {
  const userRole = useAppSelector(state => state.auth.user_role);
  const isAuthorized = useAppSelector(
    state =>
      !!state.auth.access_token &&
      !!state.auth.refresh_token &&
      !!state.auth.user_id &&
      !!state.auth.user_role,
  );

  const isAllowed = roles ? userRole && roles.includes(userRole) : true;

  if (isAuthorized && !isAllowed) {
    const path = userRole === Role.Superadmin ? '/admins' : '/devices';

    return <Navigate to={path} replace />;
  }

  return isAuthorized ? <Outlet /> : <Navigate to="/login" replace />;
};
