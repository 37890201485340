import { axiosInstance } from '@api/axios.provider';
import { FileUploadBody } from '@api/common/dto/file-upload.body';
import { ObjectIdResponse } from '@api/common/response/object-id.response';
import { GetFiltrationSystemGroupListParams } from './dto/get-filtration-system-list.params';
import { UpdateFiltrationSystemGroupBody } from './dto/update-filtration-system-group';
import { FiltrationSystemGroupListResponse } from './response/filtration-system-group-list.response';
import { FiltrationSystemModelsByGroupsResponse } from './response/filtration-system-groups.response';

export async function apiCreateFiltrationSystemGroupFromFile(
  payload: FileUploadBody,
): Promise<void> {
  const form = new FormData();
  form.append('file', payload.file);

  const response = await axiosInstance.post<void>('/api/v4/filtration-system-groups/file', form);

  return response.data;
}

/**
 * @deprecated
 */
export async function apiGetFiltrationSystemModelsByGroups(): Promise<FiltrationSystemModelsByGroupsResponse> {
  const response = await axiosInstance.get<FiltrationSystemModelsByGroupsResponse>(
    '/api/v3/filtration-system-groups',
  );

  return response.data;
}

export async function apiGetFiltrationSystemGroupList(
  params: GetFiltrationSystemGroupListParams,
): Promise<FiltrationSystemGroupListResponse> {
  const response = await axiosInstance.get<FiltrationSystemGroupListResponse>(
    '/api/v4/filtration-system-groups',
    { params },
  );

  return response.data;
}

export async function apiUpdateFiltrationSystemGroup(
  id: string,
  payload: UpdateFiltrationSystemGroupBody,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.put<ObjectIdResponse>(
    `/api/v3/filtration-system-groups/${id}`,
    payload,
  );

  return response.data;
}
