import { Operator } from '@api/common/dto/search.params.dto';
import { apiGetFiltrationSystemGroupList } from '@api/filtration-system-groups/filtration-system-groups.api';
import { FiltrationSystemGroupListResponse } from '@api/filtration-system-groups/response/filtration-system-group-list.response';
import { createErrorToast } from '@components/NotificationsHandler';
import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { actionPurgeAuthState } from '@store/auth/auth.slice';
import { toggleDirection } from '@utils/common';
import { AllColumnsStatePayload, ColumnStatePayload } from '@utils/types/column';
import { RootState } from '..';
import {
  FiltrationSystemGroupFilterColumns,
  FiltrationSystemGroupSortColumns,
  FiltrationSystemGroupStoreState,
  FiltrationSystemGroupWithSelect,
} from './types';

export const selectedFiltrationSystemGroupsAdapter = createEntityAdapter<
  FiltrationSystemGroupWithSelect,
  string
>({
  selectId: entity => entity._id,
});

/**
 * List
 */
export const actionGetFiltrationSystemGroupList = createAsyncThunk<
  FiltrationSystemGroupListResponse,
  void,
  { state: RootState }
>('filtration-system-group/get-list', async (_, thunkAPI) => {
  const { page, limit, search, column, operator, sort, direction } =
    thunkAPI.getState().filtrationSystemGroups.query;
  const offset = (page - 1) * limit;

  try {
    return await apiGetFiltrationSystemGroupList({
      offset,
      limit,
      search,
      column,
      operator,
      sort,
      direction,
    });
  } catch (error) {
    createErrorToast(error);
    return thunkAPI.rejectWithValue(error);
  }
});

/**
 * Query
 */
export const actionFiltrationSystemGroupSetPage = createAsyncThunk<
  void,
  number,
  { state: RootState }
>('filtration-system-group/set-page', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetFiltrationSystemGroupList());
});

export const actionFiltrationSystemGroupSetLimit = createAsyncThunk<
  void,
  number,
  { state: RootState }
>('filtration-system-group/set-limit', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetFiltrationSystemGroupList());
});

export const actionFiltrationSystemGroupSetSearch = createAsyncThunk<
  void,
  string | undefined,
  { state: RootState }
>('filtration-system-group/set-search', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetFiltrationSystemGroupList());
});

export const actionFiltrationSystemGroupSetColumn = createAsyncThunk<
  void,
  string,
  { state: RootState }
>('filtration-system-group/set-column', async (_, thunkAPI) => {
  if (thunkAPI.getState().filtrationSystemGroups.query.search) {
    thunkAPI.dispatch(actionGetFiltrationSystemGroupList());
  }
});

export const actionFiltrationSystemGroupSetOperator = createAsyncThunk<
  void,
  string,
  { state: RootState }
>('filtration-system-group/set-operator', async (_, thunkAPI) => {
  if (thunkAPI.getState().filtrationSystemGroups.query.search) {
    thunkAPI.dispatch(actionGetFiltrationSystemGroupList());
  }
});

export const actionFiltrationSystemGroupSetSort = createAsyncThunk<
  void,
  string | undefined,
  { state: RootState }
>('filtration-system-group/set-sort', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetFiltrationSystemGroupList());
});

const initialState: FiltrationSystemGroupStoreState = {
  data: {
    count: 0,
    list: [],
  },
  selected: selectedFiltrationSystemGroupsAdapter.getInitialState(),
  query: {
    page: 1,
    limit: 20,
    search: undefined,
    column: 'name',
    operator: 'co',
    sort: undefined,
    direction: undefined,
  },
  hidden_columns: [],
  is_loading: false,
};

export const filtrationSystemGroupSlice = createSlice({
  name: 'filtrationSystemGroupSlice',
  initialState,
  reducers: {
    /**
     * Columns
     */
    actionFiltrationSystemGroupSetColumnState: (
      state,
      action: PayloadAction<ColumnStatePayload>,
    ) => {
      const set = new Set(state.hidden_columns);
      if (action.payload.is_visible) {
        set.delete(action.payload.label);
      } else {
        set.add(action.payload.label);
      }

      state.hidden_columns = Array.from(set);
    },
    actionFiltrationSystemGroupSetAllColumnsState: (
      state,
      action: PayloadAction<AllColumnsStatePayload>,
    ) => {
      if (action.payload.is_visible) {
        state.hidden_columns = [];
      } else {
        state.hidden_columns = action.payload.labels;
      }
    },

    /**
     * Select
     */
    actionFiltrationSystemGroupSelect: (state, action: PayloadAction<{ _id: string }>) => {
      const entity = state.data.list.find(item => item._id === action.payload._id);
      if (entity) {
        entity.is_selected = true;
        selectedFiltrationSystemGroupsAdapter.addOne(state.selected, entity);
      }
    },
    actionFiltrationSystemGroupDeselect: (state, action: PayloadAction<{ _id: string }>) => {
      const entity = state.data.list.find(item => item._id === action.payload._id);
      if (entity) {
        entity.is_selected = false;
      }
      selectedFiltrationSystemGroupsAdapter.removeOne(state.selected, action.payload._id);
    },
    actionFiltrationSystemGroupSetSelectState: (state, action: PayloadAction<boolean>) => {
      const entities = state.data.list.filter(item => item.is_selected !== action.payload);
      const keys = entities.map(item => item._id);

      if (action.payload) {
        selectedFiltrationSystemGroupsAdapter.addMany(state.selected, entities);
      } else {
        selectedFiltrationSystemGroupsAdapter.removeMany(state.selected, keys);
      }

      state.data.list = state.data.list.map(item => ({ ...item, is_selected: action.payload }));
    },
    actionFiltrationSystemGroupClearSelectState: state => {
      selectedFiltrationSystemGroupsAdapter.removeAll(state.selected);
      state.data.list = state.data.list.map(item => ({ ...item, is_selected: false }));
    },
  },

  extraReducers: builder => {
    /**
     * List
     */
    builder.addCase(actionGetFiltrationSystemGroupList.pending, state => {
      state.is_loading = true;
    });
    builder.addCase(actionGetFiltrationSystemGroupList.fulfilled, (state, action) => {
      state.data.count = action.payload.count;
      state.data.list = action.payload.data.map(item => ({
        ...item,
        is_selected: state.selected.ids.includes(item._id),
      }));
      state.is_loading = false;
    });
    builder.addCase(actionGetFiltrationSystemGroupList.rejected, state => {
      state.is_loading = false;
    });

    /**
     * Query
     */
    builder.addCase(actionFiltrationSystemGroupSetPage.pending, (state, action) => {
      state.query.page = action.meta.arg;
    });
    builder.addCase(actionFiltrationSystemGroupSetLimit.pending, (state, action) => {
      state.query.limit = action.meta.arg;
      state.query.page = 1;
    });
    builder.addCase(actionFiltrationSystemGroupSetSearch.pending, (state, action) => {
      state.query.search = action.meta.arg || undefined; // to avoid empty string
      state.query.page = 1;
    });
    builder.addCase(actionFiltrationSystemGroupSetColumn.pending, (state, action) => {
      state.query.column = action.meta.arg as FiltrationSystemGroupFilterColumns;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionFiltrationSystemGroupSetOperator.pending, (state, action) => {
      state.query.operator = action.meta.arg as Operator;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionFiltrationSystemGroupSetSort.pending, (state, action) => {
      state.query.direction =
        state.query.sort === action.meta.arg ? toggleDirection(state.query.direction) : 'asc';
      state.query.sort = state.query.direction
        ? (action.meta.arg as FiltrationSystemGroupSortColumns)
        : undefined;
    });

    /**
     * Clear
     */
    builder.addMatcher(
      action => action.type === actionPurgeAuthState.type,
      () => {
        return initialState;
      },
    );
  },
});

export const {
  actionFiltrationSystemGroupSetColumnState,
  actionFiltrationSystemGroupSetAllColumnsState,
  actionFiltrationSystemGroupSelect,
  actionFiltrationSystemGroupDeselect,
  actionFiltrationSystemGroupSetSelectState,
  actionFiltrationSystemGroupClearSelectState,
} = filtrationSystemGroupSlice.actions;
