export type NotificationStoreState = {
  types: TypesItem[];
  isLoading: boolean;
  offset: number;
  limit: number;
  data: {
    count: number;
    data: Notification[];
  };
};

export type TypesItem = {
  type: NotificationType;
  title: string;
  value: boolean;
};

export type Notification = {
  _id: string;
  type: NotificationType;
  args?: {
    cartridge_model?: string;
    filtration_system_model?: string;
    sensor_location?: string;
  };
  device: {
    _id: string;
    bssid: string;
  };
  created_at: string;
  updated_at: string;
};

export enum NotificationType {
  CARTRIDGE_REPLACED = 'CARTRIDGE_REPLACED',
  CARTRIDGE_EXPIRED_SOON = 'CARTRIDGE_EXPIRED_SOON',
  CARTRIDGE_EXPIRED = 'CARTRIDGE_EXPIRED',
  FILTRATION_SYSTEM_CHANGED = 'FILTRATION_SYSTEM_CHANGED',
  FIRMWARE_UPDATED = 'FIRMWARE_UPDATED',
  FIRMWARE_AVAILABLE = 'FIRMWARE_AVAILABLE',
  BATTERY_LOW = 'BATTERY_LOW',
  BATTERY_DISCHARGED = 'BATTERY_DISCHARGED',
  DEVICE_STATUS_OFFLINE = 'DEVICE_STATUS_OFFLINE',
  DEVICE_STATUS_LEAK = 'DEVICE_STATUS_LEAK',
  DEVICE_FAILURE_PUMP = 'DEVICE_FAILURE_PUMP',
  DEVICE_FAILURE_PRESSURE_SENSOR = 'DEVICE_FAILURE_PRESSURE_SENSOR',
  DEVICE_FAILURE_FLOW_SENSOR_INPUT = 'DEVICE_FAILURE_FLOW_SENSOR_INPUT',
  DEVICE_FAILURE_FLOW_SENSOR_OUTPUT = 'DEVICE_FAILURE_FLOW_SENSOR_OUTPUT',
  DEVICE_FAILURE_TEMPERATURE_SENSOR_INPUT = 'DEVICE_FAILURE_TEMPERATURE_SENSOR_INPUT',
  DEVICE_FAILURE_TEMPERATURE_SENSOR_OUTPUT = 'DEVICE_FAILURE_TEMPERATURE_SENSOR_OUTPUT',
  DEVICE_FAILURE_TDS_SENSOR_INPUT = 'DEVICE_FAILURE_TDS_SENSOR_INPUT',
  DEVICE_FAILURE_TDS_SENSOR_OUTPUT = 'DEVICE_FAILURE_TDS_SENSOR_OUTPUT',
  DEVICE_FAILURE_NO_WATER = 'DEVICE_FAILURE_NO_WATER',
  WATER_TEMPERATURE_TOO_HIGH = 'WATER_TEMPERATURE_TOO_HIGH',
  WATER_TEMPERATURE_TOO_LOW = 'WATER_TEMPERATURE_TOO_LOW',
  WATER_PRESSURE_TOO_HIGH = 'WATER_PRESSURE_TOO_HIGH',
  WATER_PRESSURE_TOO_LOW = 'WATER_PRESSURE_TOO_LOW',
  WATER_TDS_TOO_HIGH = 'WATER_TDS_TOO_HIGH',
}
