import { VictoryThemeDefinition } from 'victory-core';

export const CHART_WIDTH = 700;
export const CHART_HEIGHT = 400;
export const CHART_PADDING_LEFT = 50;
export const CHART_PADDING_RIGHT = 20;

export type VictoryColors = {
  background: string;
  axis: string;
  grid: string;
};

export class VictoryBaseTheme {
  colors: VictoryColors;

  constructor(colors: VictoryColors) {
    this.colors = colors;
  }

  public get theme(): VictoryThemeDefinition {
    const letterSpacing = 'normal';
    const padding = 8;

    const baseProps = {
      width: CHART_WIDTH,
      height: CHART_HEIGHT,
      // padding: 50,
    };

    const baseLabelStyles = {
      fontSize: 12,
      letterSpacing,
      padding,
      fill: this.colors.axis,
      stroke: 'transparent',
      strokeWidth: 0,
    };

    const centeredLabelStyles = {
      ...baseLabelStyles,
      textAnchor: 'middle',
    };

    const strokeLinecap = 'round';
    const strokeLinejoin = 'round';

    return {
      // ...VictoryTheme.material,
      chart: {
        ...baseProps,
        padding: {
          top: 20,
          bottom: 30,
          left: CHART_PADDING_LEFT,
          right: CHART_PADDING_RIGHT,
        },
      },
      group: {
        ...baseProps,
        width: 5,
      },
      stack: baseProps,
      area: {
        ...baseProps,
        style: {
          data: {
            fill: this.colors.background,
          },
          labels: baseLabelStyles,
        },
      },
      axis: {
        ...baseProps,
        style: {
          axis: {
            fill: 'transparent',
            stroke: this.colors.axis,
            strokeWidth: 1,
            strokeLinecap,
            strokeLinejoin,
          },
          axisLabel: {
            ...centeredLabelStyles,
            fill: this.colors.axis,
            fontSize: 14,
            padding: 35,
            stroke: 'transparent',
            textAnchor: 'middle',
          },
          grid: {
            fill: 'none',
            stroke: this.colors.grid,
            strokeLinecap,
            strokeLinejoin,
            pointerEvents: 'painted',
          },
          ticks: {
            fill: 'transparent',
            size: 5,
            stroke: this.colors.axis,
            strokeWidth: 1,
            strokeLinecap,
            strokeLinejoin,
          },
          tickLabels: {
            ...baseLabelStyles,
            fill: this.colors.axis,
          },
        },
      },
      bar: {
        ...baseProps,
        style: {
          data: {
            padding,
            strokeWidth: 0,
          },
          labels: baseLabelStyles,
        },
      },
      line: {
        ...baseProps,
        style: {
          data: {
            strokeWidth: 3,
          },
        },
      },
      scatter: {
        ...baseProps,
        style: {
          data: {
            fill: this.colors.background,
            opacity: 1,
            stroke: 'transparent',
            strokeWidth: 3,
          },
          labels: baseLabelStyles,
        },
      },

      tooltip: {
        style: {
          ...baseLabelStyles,
          padding: 0,
          pointerEvents: 'none',
          fill: '#fff',
        },
        flyoutStyle: {
          stroke: 'transparent',
          strokeWidth: 0,
          fill: 'rgba(12,12,12,0.9)',
          pointerEvents: 'none',
        },
        flyoutPadding: 8,
        cornerRadius: 10,
        pointerLength: 10,
      },
      voronoi: {
        style: {
          data: {
            fill: 'transparent',
            stroke: 'transparent',
            strokeWidth: 0,
          },
          labels: {
            ...baseLabelStyles,
            padding: 5,
            pointerEvents: 'none',
            fill: '#fff',
          },
          flyout: {
            stroke: 'transparent',
            strokeWidth: 0,
            fill: 'rgba(12,12,12,0.9)',
            pointerEvents: 'none',
          },
        },
      },
    };
  }
}
