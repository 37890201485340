import { Switch, styled } from '@mui/material';

export const ThemeSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#fff',
      '& .MuiSwitch-thumb:before': {
        backgroundImage:
          "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 40 40'%3E%3Cpath fill='%23000' d='M13.14 17.71a2.29 2.29 0 1 0 0-4.57 2.29 2.29 0 0 0 0 4.57Zm2.29 8a1.14 1.14 0 1 1-2.29 0 1.14 1.14 0 0 1 2.29 0Z'/%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M20 36a16 16 0 1 0 0-32 16 16 0 0 0 0 32Zm.13-26.89a18.18 18.18 0 0 0-5.06-2.1 13.72 13.72 0 0 0-8.53 15.45 2.28 2.28 0 0 1 3.17 2.11c0 1-.65 1.86-1.55 2.17a13.76 13.76 0 0 0 7.28 6.02l-.01-.19a2.29 2.29 0 1 1 4.36.96H20c2.93 0 5.64-.91 7.86-2.47.45-1.24.78-2.53.95-3.88a3.43 3.43 0 1 1 .16-2.54 18.2 18.2 0 0 0-2.65-9.35 4.57 4.57 0 1 1-6.19-6.18Z' clip-rule='evenodd'/%3E%3C/svg%3E\")",
      },
    },
  },
  '& .MuiSwitch-thumb': {
    // backgroundColor: theme.palette.mode === 'dark' ? '#16283c' : '#fff',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 40 40'%3E%3Cpath fill='%231976d2' fill-rule='evenodd' d='M18.4 5.6a1.6 1.6 0 1 1 3.2 0v1.6a1.6 1.6 0 0 1-3.2 0V5.6ZM29.6 20a9.6 9.6 0 1 1-19.2 0 9.6 9.6 0 0 1 19.2 0ZM18.4 32.8a1.6 1.6 0 0 1 3.2 0v1.6a1.6 1.6 0 0 1-3.2 0v-1.6ZM29.05 8.69a1.6 1.6 0 0 1 2.26 2.26l-1.13 1.13a1.6 1.6 0 0 1-2.26-2.26l1.13-1.13ZM9.82 27.92a1.6 1.6 0 0 1 2.26 2.26l-1.13 1.13a1.6 1.6 0 1 1-2.26-2.26l1.13-1.13ZM34.4 18.4a1.6 1.6 0 0 1 0 3.2h-1.6a1.6 1.6 0 0 1 0-3.2h1.6Zm-27.2 0a1.6 1.6 0 1 1 0 3.2H5.6a1.6 1.6 0 0 1 0-3.2h1.6Zm24.11 12.91a1.6 1.6 0 0 1-2.26 0l-1.13-1.13a1.6 1.6 0 0 1 2.26-2.26l1.13 1.13a1.6 1.6 0 0 1 0 2.26ZM12.08 9.81a1.6 1.6 0 1 1-2.26 2.27l-1.13-1.13a1.6 1.6 0 1 1 2.26-2.26l1.13 1.13Zm3.22 8.2c-.27-.63-1.13-.75-1.35-.1a6.39 6.39 0 0 0 8.92 7.81c.61-.3.38-1.14-.28-1.33a11.23 11.23 0 0 1-7.29-6.38Z' clip-rule='evenodd'/%3E%3C/svg%3E\")",
    },
  },
}));
