import { axiosInstance } from '@api/axios.provider';
import { ChangePasswordDto } from '@api/common/dto/change-password.dto';
import { ChangeStatusDto } from '@api/common/dto/change-status.dto';
import { UpdatePasswordDto } from '@api/common/dto/update-password.dto';
import { ObjectIdResponse } from '@api/common/response/object-id.response';
import { CreateAdminDto } from './dto/create-admin.dto';
import { GetAdminListParams } from './dto/get-admin-list.params';
import { UpdateAdminProfileDto } from './dto/update-admin-profile.dto';
import { UpdateAdminDto } from './dto/update-admin.dto';
import { AdminListResponse } from './response/admin-list.response';
import { AdminProfileResponse } from './response/admin-profile.response';
import { AdminResponse } from './response/admin.response';
import { UpdateAdminProfileResponse } from './response/update-admin-profile.response';

export async function apiCreateAdmin(payload: CreateAdminDto): Promise<ObjectIdResponse> {
  const response = await axiosInstance.post<ObjectIdResponse>('/api/v3/admins', payload);

  return response.data;
}

export async function apiGetAdminList(params: GetAdminListParams): Promise<AdminListResponse> {
  const response = await axiosInstance.get<AdminListResponse>('/api/v3/admins', { params });

  return response.data;
}

export async function apiDeleteManyAdmins(ids: string[]): Promise<void> {
  const response = await axiosInstance.delete<void>('/api/v3/admins', { data: { ids } });

  return response.data;
}

export async function apiGetAdmin(id: string): Promise<AdminResponse> {
  const response = await axiosInstance.get<AdminResponse>(`/api/v3/admins/${id}`);

  return response.data;
}

export async function apiUpdateAdmin(
  id: string,
  payload: UpdateAdminDto,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.patch<ObjectIdResponse>(`/api/v3/admins/${id}`, payload);

  return response.data;
}

export async function apiDeleteAdmin(id: string): Promise<void> {
  const response = await axiosInstance.delete<void>(`/api/v3/admins/${id}`);

  return response.data;
}

export async function apiChangeAdminPassword(
  id: string,
  payload: ChangePasswordDto,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.patch<ObjectIdResponse>(
    `/api/v3/admins/${id}/change-password`,
    payload,
  );

  return response.data;
}

export async function apiChangeAdminStatus(
  id: string,
  payload: ChangeStatusDto,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.patch<ObjectIdResponse>(
    `/api/v3/admins/${id}/change-status`,
    payload,
  );

  return response.data;
}

export async function apiGetAdminProfile(): Promise<AdminProfileResponse> {
  const response = await axiosInstance.get<AdminProfileResponse>('/api/v3/admins/me/profile');

  return response.data;
}

export async function apiUpdateAdminProfile(
  payload: UpdateAdminProfileDto,
): Promise<UpdateAdminProfileResponse> {
  const response = await axiosInstance.patch<UpdateAdminProfileResponse>(
    '/api/v3/admins/me/profile',
    payload,
  );

  return response.data;
}

export async function apiUpdateAdminPassword(
  payload: UpdatePasswordDto,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.patch<ObjectIdResponse>(
    '/api/v3/admins/me/profile/password',
    payload,
  );

  return response.data;
}
