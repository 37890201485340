import { useAppSelector } from '@/hooks/store.hooks';
import { AccountCircle, BarChart } from '@mui/icons-material';
import AccountIcon from '@mui/icons-material/AccountBoxOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import CalendarIcon from '@mui/icons-material/DateRangeOutlined';
import DevicesIcon from '@mui/icons-material/Devices';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import NotificationsIcon from '@mui/icons-material/NotificationImportant';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';
import {
  Badge,
  CSSObject,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Theme,
  styled,
  useTheme,
} from '@mui/material';
import { selectedDevicesAdapter } from '@store/device/device.slice';
import { Role } from '@utils/enum/Role';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const drawerWidth = 220;

export const SideNav: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme<Theme>();

  const userRole = useAppSelector(state => state.auth.user_role)!;
  const isNavSideBarOpen = useAppSelector(state => state.theme.isNavSideBarOpen);

  const devices = useAppSelector(state => state.devices.selected);
  const selectedDevices = selectedDevicesAdapter.getSelectors().selectAll(devices);

  const openedMixin = (): CSSObject => ({
    position: 'relative',
    overflowX: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  });

  const closedMixin = (): CSSObject => ({
    position: 'relative',
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(7)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ open }) => ({
    width: drawerWidth,
    height: 'inherit',
    // flexShrink: 0,
    // whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(),
      '& .MuiDrawer-paper': openedMixin(),
    }),
    ...(!open && {
      ...closedMixin(),
      '& .MuiDrawer-paper': closedMixin(),
      '& .MuiTypography-body2': { display: 'none' },
    }),
  }));

  const ListIcon = styled(ListItemIcon)(() => ({
    minWidth: 0,
    marginRight: 16,
  }));

  return (
    <Drawer variant="permanent" anchor="left" open={isNavSideBarOpen}>
      <List style={{ paddingTop: 0, paddingBottom: 0 }}>
        {[Role.Admin, Role.Vendor].includes(userRole) && (
          <ListItemButton
            color="primary"
            key="Devices"
            selected={location.pathname === '/devices'}
            onClick={() => {
              navigate('/devices');
            }}
          >
            <ListIcon>
              <Badge
                color="secondary"
                badgeContent={selectedDevices.length}
                invisible={isNavSideBarOpen}
                max={999}
                sx={{
                  '& .MuiBadge-badge': {
                    padding: '0 4px',
                  },
                }}
              >
                <DevicesIcon color="primary" />
              </Badge>
            </ListIcon>
            <ListItemText
              primary={t('side_navigator.devices.primary')}
              secondary={t('side_navigator.devices.secondary', {
                count: selectedDevices.length,
              })}
            />
          </ListItemButton>
        )}

        {[Role.Admin, Role.Vendor].includes(userRole) && (
          <ListItemButton
            color="primary"
            key="DeviceModels"
            selected={location.pathname === '/devices/models'}
            onClick={() => {
              navigate('/devices/models');
            }}
          >
            <ListIcon>
              <ImportantDevicesIcon color="primary" />
            </ListIcon>
            <ListItemText primary={t('side_navigator.device_models.primary')} />
          </ListItemButton>
        )}

        {[Role.Admin].includes(userRole) && (
          <ListItemButton
            color="primary"
            key="FiltrationSystems"
            selected={location.pathname === '/filtration-systems'}
            onClick={() => {
              navigate('/filtration-systems');
            }}
          >
            <ListIcon>
              <AutoAwesomeMosaicIcon color="primary" />
            </ListIcon>
            <ListItemText primary={t('side_navigator.filtration_systems.primary')} />
          </ListItemButton>
        )}

        {[Role.Admin].includes(userRole) && (
          <ListItemButton
            color="primary"
            key="FiltrationSystemGroups"
            selected={location.pathname === '/filtration-system-groups'}
            onClick={() => {
              navigate('/filtration-system-groups');
            }}
          >
            <ListIcon>
              <ViewListIcon color="primary" />
            </ListIcon>
            <ListItemText primary={t('side_navigator.filtration_system_groups.primary')} />
          </ListItemButton>
        )}

        {[Role.Admin].includes(userRole) && (
          <ListItemButton
            color="primary"
            key="CartridgeTypes"
            selected={location.pathname === '/cartridge-types'}
            onClick={() => {
              navigate('/cartridge-types');
            }}
          >
            <ListIcon>
              <AppsIcon color="primary" />
            </ListIcon>
            <ListItemText primary={t('side_navigator.cartridge_types.primary')} />
          </ListItemButton>
        )}

        {[Role.Superadmin].includes(userRole) && (
          <ListItemButton
            color="primary"
            key="Admins"
            selected={location.pathname === '/admins'}
            onClick={() => {
              navigate('/admins');
            }}
          >
            <ListIcon>
              <AccountIcon color="primary" />
            </ListIcon>
            <ListItemText primary={t('side_navigator.admins.primary')} />
          </ListItemButton>
        )}

        {[Role.Admin].includes(userRole) && (
          <ListItemButton
            color="primary"
            key="Vendors"
            selected={location.pathname === '/vendors'}
            onClick={() => {
              navigate('/vendors');
            }}
          >
            <ListIcon>
              <StoreOutlinedIcon color="primary" />
            </ListIcon>
            <ListItemText primary={t('side_navigator.vendors.primary')} />
          </ListItemButton>
        )}

        {[Role.Admin].includes(userRole) && (
          <ListItemButton
            color="primary"
            key="Users"
            selected={location.pathname === '/users'}
            onClick={() => {
              navigate('/users');
            }}
          >
            <ListIcon>
              <AccountCircle color="primary" />
            </ListIcon>
            <ListItemText primary={t('side_navigator.users.primary')} />
          </ListItemButton>
        )}

        {[Role.Admin].includes(userRole) && (
          <ListItemButton
            color="primary"
            key="Calendar"
            selected={location.pathname === '/calendar'}
            onClick={() => {
              navigate('/calendar');
            }}
          >
            <ListIcon>
              <CalendarIcon color="primary" />
            </ListIcon>
            <ListItemText
              primary={t('side_navigator.calendar.primary')}
              secondary={t('side_navigator.calendar.secondary')}
            />
          </ListItemButton>
        )}

        {[Role.Admin, Role.Vendor].includes(userRole) && (
          <ListItemButton
            color="primary"
            key="Charts"
            selected={location.pathname === '/charts'}
            onClick={() => {
              navigate('/charts');
            }}
          >
            <ListIcon>
              <BarChart color="primary" />
            </ListIcon>
            <ListItemText
              primary={t('side_navigator.charts.primary')}
              secondary={t('side_navigator.charts.secondary')}
            />
          </ListItemButton>
        )}

        {[Role.Admin, Role.Vendor].includes(userRole) && (
          <>
            <Divider />

            <ListItemButton
              color="primary"
              key="Notifications"
              selected={location.pathname === '/notifications'}
              onClick={() => {
                navigate('/notifications');
              }}
            >
              <ListIcon>
                <NotificationsIcon color="primary" />
              </ListIcon>
              <ListItemText primary={t('side_navigator.notifications.primary')} />
            </ListItemButton>
          </>
        )}
      </List>
    </Drawer>
  );
};
