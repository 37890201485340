import { useAppSelector } from '@/hooks/store.hooks';
import { NotificationsHandler } from '@components/NotificationsHandler';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Appbar } from './Appbar';
import { Router } from './Router';
import { SideNav } from './SideNav';
import * as classes from './app.module.scss';

export const App: React.FC = () => {
  const isAuthorized = useAppSelector(
    state =>
      !!state.auth.access_token &&
      !!state.auth.refresh_token &&
      !!state.auth.user_id &&
      !!state.auth.user_role,
  );

  const location = useLocation();
  const routesWithoutNavigation = ['/email', '/password'];
  const isNavigationVisible =
    isAuthorized && routesWithoutNavigation.every(route => !location.pathname.startsWith(route));

  return (
    <div className={classes.root}>
      {isNavigationVisible && <Appbar />}

      <div className={classes.scene_wrapper}>
        {isNavigationVisible && <SideNav />}

        <div className={classes.scene}>
          <Router />
        </div>
      </div>

      <NotificationsHandler />
    </div>
  );
};
