import { useAppSelector } from '@/hooks/store.hooks';
import { Role } from '@utils/enum/Role';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const PublicRoute: React.FC = () => {
  const userRole = useAppSelector(state => state.auth.user_role);
  const isAuthorized = useAppSelector(
    state =>
      !!state.auth.access_token &&
      !!state.auth.refresh_token &&
      !!state.auth.user_id &&
      !!state.auth.user_role,
  );

  const path = userRole === Role.Superadmin ? '/admins' : '/devices';

  return !isAuthorized ? <Outlet /> : <Navigate to={path} />;
};
