import { axiosInstance } from '@api/axios.provider';
import { FileUploadBody } from '@api/common/dto/file-upload.body';
import { ObjectIdResponse } from '@api/common/response/object-id.response';
import { GetCartridgeTypeListParams } from './dto/get-cartridge-type-list.params';
import { UpdateCartridgeTypeBody } from './dto/update-cartridge-type.body';
import { CartridgeTypeListResponse } from './response/cartridge-type-list.response';

export async function apiCreateCartridgeTypeFromFile(payload: FileUploadBody): Promise<void> {
  const form = new FormData();
  form.append('file', payload.file);

  const response = await axiosInstance.post<void>('/api/v3/cartridge-types/file', form);

  return response.data;
}

export async function apiGetCartridgeTypeList(
  params: GetCartridgeTypeListParams,
): Promise<CartridgeTypeListResponse> {
  const response = await axiosInstance.get<CartridgeTypeListResponse>('/api/v3/cartridge-types', {
    params,
  });

  return response.data;
}

export async function apiUpdateCartridgeType(
  id: string,
  payload: UpdateCartridgeTypeBody,
): Promise<ObjectIdResponse> {
  const response = await axiosInstance.put<ObjectIdResponse>(
    `/api/v3/cartridge-types/${id}`,
    payload,
  );

  return response.data;
}
