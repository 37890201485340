import '@/styles/style.scss';
import './locales/i18n';

import { App } from '@/app/App';
import { AxiosProvider } from '@api/axios.provider';
import { ThemeProvider } from '@components/ThemeProvider';
import { persistor, store } from '@store/index';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

const container = document.querySelector('#root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider>
        <AxiosProvider />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
);
