import i18next from 'i18next';
import { Settings } from 'luxon';
import { initReactI18next } from 'react-i18next';

Settings.defaultLocale = 'en-GB';

import notification_en from '@locales/en/notification.json';
import translation_en from '@locales/en/translation.json';

export const resources = {
  en: {
    translation: translation_en,
    notification: notification_en,
  },
} as const;

export const defaultNS: keyof (typeof resources)['en'] = 'translation';
export const ns: Array<keyof (typeof resources)['en']> = ['translation', 'notification'];

export const i18n = i18next;
i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',

  nsSeparator: ':',
  keySeparator: '.',

  defaultNS,
  ns,

  resources,

  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});
