import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { VictoryBaseTheme } from '@utils/charts/victory-base.theme';
import React from 'react';
import { useAppSelector } from '../hooks/store.hooks';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
      // main: '#297ACC',
    },
    secondary: {
      main: '#8ed1f3',
    },
    error: {
      main: '#d32f2f',
    },
  },

  charts: {
    victory: new VictoryBaseTheme({
      background: '#FFF',
      axis: '#999',
      grid: '#E6E6E6',
    }).theme,

    axis: '#999',
    grid: '#E6E6E6',

    waterProductionWastePermeate: ['#007AFF', '#93D8FF'],
    waterProductionWasteFeed: ['#429321', '#B4EC51'],
    waterPurificationPermeate: ['#007AFF', '#93D8FF'],
    waterPurificationFeed: ['#429321', '#B4EC51'],
    waterUsageEfficiency: '#007AFF',
    waterPurificationEfficiency: '#BD10E0',
    productionRate: '#00A1E4',
    instantRecoveryRate: '#FC0078',
    waterPressure: '#007AFF',
    waterTemperature: '#FF3B30',
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#297ACC',
    },
    secondary: {
      main: '#8ed1f3',
    },
    error: {
      main: '#d32f2f',
    },
    background: {
      default: '#121212',
      // default: '#171717',
      paper: '#1f1f1f',
    },
  },

  charts: {
    victory: new VictoryBaseTheme({
      background: '#2C2C2E',
      axis: '#666666',
      grid: '#3A3A3C',
    }).theme,

    axis: '#666666',
    grid: '#3A3A3C',

    waterProductionWastePermeate: ['#297ACC', '#66AFCC'],
    waterProductionWasteFeed: ['#1D8036', '#26A646'],
    waterPurificationPermeate: ['#297ACC', '#66AFCC'],
    waterPurificationFeed: ['#1D8036', '#26A646'],
    waterUsageEfficiency: '#297ACC',
    waterPurificationEfficiency: '#AC4CBF',
    productionRate: '#4C9DBF',
    instantRecoveryRate: '#BF3981',
    waterPressure: '#297ACC',
    waterTemperature: '#CC2929',
  },
});

export const ThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isLightTheme = useAppSelector(state => state.theme.isLightTheme);

  return (
    <MuiThemeProvider theme={isLightTheme ? lightTheme : darkTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
